@import "./styles/";

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  /*user-select: none;*/
}

.feedback {
  margin-top: -25%;
  margin-left: -2.5%;
}

@media only screen and (max-width: 1024px) {
  .feedback {
    z-index: 6;
  }
}
body {
  margin: 0 auto;
}
label {
  cursor: pointer;
}
#root {
  overflow-x: hidden;
}

.notFoundWrapper {
  padding-top: 110px;
}

.errorBoundary {
  padding: 14px;
  font-size: 16px;
}
.errorElementHeight {
  height: 500px;
  top: 200px;
  display: block;
  padding-top: 150px;
}
