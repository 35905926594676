@import "../../../styles/_const.scss";

//---------------------------------Work Section

.slick-prev:before {
  color: transparent;
}

.slick-next:before {
  color: transparent;
}
.slick-next:before,
.slick-prev:before {
  color: transparent;
}

.slick-list {
  padding-left: 10px;
}
