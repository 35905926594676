.textColor {
  color: #1c1b29;
  width: 15vw;
}
.textColorRUS {
  color: #1c1b29;
  width: 21vw;
}
.t {
  padding: 13.02081vw 0 5.20835vw 0;
}
.styleForAbout {
  column-gap: 0vw;
  width: 100%;
  justify-content: center;
}
.styleForAboutRUS {
  column-gap: 3vw;
  width: 100%;
  justify-content: center;
}
.top {
  margin-bottom: 2.7778vh;
}
@media only screen and (max-width: 1024px) and (min-width: 501px) {
  .t {
    padding: 17.21vw 0 5.20835vw 0;
  }
}
@media only screen and (max-width: 500px) and (min-width: 400px) {
  .t {
    padding: 20.2084vw 0 5.20835vw 0;
  }
}
@media only screen and (max-width: 399px) {
  .t {
    padding: 27.2057vw 0 5.20835vw 0; //108.551
  }
}
@media only screen and (max-width: 1024px) {
  .textColor {
    width: auto;
  }
  .textColorRUS {
    width: auto;
  }
}
