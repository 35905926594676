.title {
  margin-top: 2.86458333335vw;
}

.shareImage {
  margin: 1.04166666667vw 1.04166666667vw 0 0;
  width: 2.5vw;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .title {
    margin-top: 37px;
  }

  .shareImage {
    width: 30px;
    height: 30px;
    margin: 15px 15px 0 0;
  }
}
