@media only screen and (max-width: 850px) {
  .slick-slider .slick-list {
    padding-left: 0px;
  }
}
.slick-dots li {
  margin: 0 15px;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #1c1b29;
}

.slick-dots li button:before {
  font-size: 0.8vw !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 1;
  color: #767575;
}
@media only screen and (max-width: 850px) {
  .slick-dots li button:before {
    font-size: 15px !important;

    line-height: 0px;
    width: 10px;
    height: 10px;
  }
  .slick-dots li {
    margin: 0 20px;
    width: 10px;
    height: 10px;
  }
  .slick-dots li button {
    padding: 0;
    width: 0;
    height: 0px;
  }
}
