@import "../../../styles/_const.scss";

//----------------------------------Testimonials Section
.testimonials-section-box {
  padding: 0 10vw;
  background: white;
  padding-bottom: 1.8519vh;
}
.slick-list {
  padding-left: 0px;
}
//------------------------------------Testimonials Section Slider

.testimonials-section-auter-and-text {
  width: 100%;
}

.legend-author {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: capitalize;
  font-size: 2.34375vw;
  padding: 9.2593vh 0 0px 0;
  width: 82%;
  margin: 0 auto;
  line-height: 2.864585vw;
}

.legend-text {
  font-size: 1.302081vw;
  line-height: 1.822919vw;
  letter-spacing: 0px;
  color: $main-grey-color;
  margin: 0 auto;
  width: 70%;
  height: 25.2vh;

  text-align: center;
  display: grid;
  align-items: center;
}

//------------------------------------------------------------------------------------------MOBILE VERSION

@media only screen and (max-width: 850px) {
  .testimonials-section-box {
    padding: 0px;

    padding-bottom: 2.2vh;
  }

  .legend-author {
    font-size: 20px;

    line-height: 23px;
    padding: 50px 0 21px;
  }

  .legend-text {
    text-align: justify;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0px;
    color: $main-grey-color;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 40px;
  }
}
