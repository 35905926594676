.backgroundStyle {
  padding: 12vw 0 5vw 0;
}

.boxStyle {
  width: 80%;
  padding: 3.64584vw 5.41669vw 5.20835vw 5.41669vw;
  background-color: white;
  box-shadow: 0px 10px 30px #00000033;
}
a {
  text-decoration: none !important;
}
.textStyle {
  margin-bottom: 60px;
}
.rowWrapper {
  display: flex;
  margin-bottom: 52px;
}
.aboutStyle,
.stylesAboutId {
  background-color: white;
}
.firstRowTextStyle,
.newStyle {
  color: #1c1b29;
}
@media only screen and (max-width: 1024px) {
  .boxStyle {
    width: 90%;
    margin-left: 5%;
    padding: 15px 25px 25px 25px;
    margin-top: -17vh;
    position: relative;
  }
  .textStyle {
    font-size: 25px !important;
    margin-bottom: 25px;
  }
  .rowWrapper {
    display: grid;
    margin-bottom: 37px;
  }
}
