@import "../../../styles/_const.scss";

//-------------------------Clients Section
.idClients {
  padding: 0px 0 5.557vh 0;
  color: transparent;
}
.IDsolutions {
  margin: 0 0 9.2593vh 0;
}
.clients-section-clients-items {
  width: 9.375vw;
}
.clients-section-clients-items-box {
  margin-top: 4.62965vh;
  align-self: baseline;
}

//-------------------------Clients Section Clients
.clients-section-box {
  margin: 0;
}

.clients-section-text {
  font-size: 2.34375vw;
  line-height: 2.864585vw;
  margin-top: 3.7037vh;
  margin-bottom: 1.8519vh;
  font-weight: bold;
  text-align: center;
}

.clients-section-clients-box {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  justify-items: center;
  justify-content: center;
  column-gap: 8.25vw;
}

//------------------------------------------------------------------------------------------MOBILE VERSION
// @media only screen and (max-width: 1024px) and (min-width: 600px) {
//   .clients-section-clients-items {
//     width: 12vw !important;
//   }
//   .clients-section-clients-box {
//     column-gap: 5vw !important;
//   }
// }

@media only screen and (max-width: 1024px) {
  .idClients {
    padding: 0px 0 50px 0;
    color: transparent;
  }
  .clients-section-box {
    margin: 0 0 50px 0;
  }
  .clients-section-clients-box {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-items: center;
    justify-content: center;
    column-gap: 2.5vw;
  }
  .clients-section-clients-items {
    width: 15vw;
    height: 8vw;
  }
  .clients-section-text {
    font-size: 25px;
    line-height: 29px;
    font-weight: bold;
    text-align: center;
    color: #767575;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .clients-section-clients-items-box {
    margin-top: 35px;
  }
}
