@import "../../../styles/_const.scss";

//-----------------------------Larg IMG With Right Box
.ecommerce-why-redkite-box {
  margin-top: 9.2593vh;
  margin-bottom: 15.7407vh;
}

.ecommerce-why-redkite-img {
  width: 100vw;
}

.ecommerce-why-redkite-right-section {
  display: grid;
}
.ecommerce-second-box-img {
  width: 100%;
}
//-----------------------------Larg IMG With Right Box      Right Box
.ecommerce-why-redkite-right-box-box {
  box-shadow: $main-box-shadow;
  justify-self: end;
  background-color: $main-white-color;
  width: 43vw;
  margin-top: 6.4815vh;
  margin-bottom: -6.4815vh;
}

//-----------------------------Larg IMG With Right Box      Right Sub Box
.ecommerce-why-redkite-right-subbox-box {
  padding-top: 9.2593vh;
  padding-left: 6.4vw;
  padding-right: 6vw;
}

//-----------------------------Larg IMG With Right Box      Right Sub Box Buttons
.ecommerce-why-redkite-right-subbox-button {
  border-radius: 30px;
  border: 3px solid $main-title-and-text-color;
  padding: 1.48148vh 2.864586vw;
  background-color: $main-white-color;
  font-size: 1.041669vw;
  line-height: 1.041669vw;
  font-weight: bold;
  margin-bottom: 9.2593vh;
  outline: none;
  transition: 0.5s;
}
.ecommerce-why-redkite-right-subbox-button:hover {
  background-color: #1c1b29;
  color: #efefef;
  transition: 0.5s;
  color: #fff;
}

//-----------------------------Larg IMG With Right Box      Right Sub Box Text
.ecommerce-why-redkite-right-subbox-title {
  font-weight: bold;
  font-size: 2.34375vw;
  color: $main-title-and-text-color;
  line-height: 2.34375vw;
}
.ecommerce-why-redkite-right-subbox-text {
  font-size: 1.302081vw;
  color: $main-title-and-text-color;
  line-height: 1.822919vw;
  margin: 4.6296vh 0;
}
.ecommerce-why-redkite-right-subbox-text ul li {
  margin-left: -1vw;
}

//------------------------------------------------------------------------------------------MOBILE VERSION

@media only screen and (max-width: 1024px) {
  .ecommerce-why-redkite-box {
    position: relative;

    max-width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .ecommerce-why-redkite-right-box-box {
    box-shadow: $main-box-shadow;
    justify-self: center;

    background-color: $main-white-color;
    width: 89.34%;
    height: 100%;
    margin-top: -9.2593vh;
    margin-bottom: 9.2593vh;
  }

  .ecommerce-why-redkite-right-subbox-box {
    padding-top: 35px;
    padding-left: 20px;
    padding-right: 9px;

    width: 100%;
  }
  .ecommerce-why-redkite-right-subbox-text {
    padding-left: 25px;
    margin: 30px 0;
  }
  .ecommerce-why-redkite-right-subbox-text ul li {
    margin-left: -8vw;
  }
  .ecommerce-why-redkite-right-subbox-button {
    border-radius: 30px;
    border: 2px solid $main-title-and-text-color;
    padding: 14px 30px;
    background-color: $main-white-color;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 35px;
  }
  .ecommerce-why-redkite-right-subbox-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: $main-title-and-text-color;
  }
  .ecommerce-why-redkite-right-subbox-text {
    font-size: 14px;
    line-height: 16px;
    color: $main-title-and-text-color;
    margin-bottom: 35px;
    margin-left: -22px;
  }
  .ecommerce-second-box-img {
    height: 300px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 601px) {
  .ecommerce-why-redkite-right-box-box {
    box-shadow: $main-box-shadow;
    justify-self: center;

    background-color: $main-white-color;
    width: 70%;
    height: 100%;
    margin-top: -9.2593vh;
    margin-bottom: 9.2593vh;
  }
}
