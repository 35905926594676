@import "../../../styles/_const.scss";

//----------------------------Features List
.ecommerce-page-redkite-will-box {
  box-shadow: $main-box-shadow;
  margin-left: 6vw;
  margin-right: 6vw;
  margin-top: -12.963vh;
  color: $main-title-and-text-color;
  max-width: 100%;
  position: relative;
  background-color: $main-white-color;
  padding-bottom: 9.2593vh;
}

.ecommerce-page-redkite-will-box-title {
  text-align: center;
  font-size: 2.34375vw;
  font-weight: bold;
  padding-top: 9.2593vh;
  z-index: 11;
  line-height: 2.864585vw;
}

//----------------------------Features List        Box
.ecommerce-page-redkite-will-items-box {
  display: flex;
  width: 100%;

  column-gap: 2.604165vw;
}
.boxWrapper {
  padding: 0 2.08335vw;
}
//----------------------------Features List        Box  Items
.ecommerce-page-redkite-will-items-img {
  padding-top: 9.2593vh;
  padding-bottom: 3.7037vh;
  width: 2.6049vw;
}

.ecommerce-page-redkite-will-items-text {
  text-align: center;
  font-size: 1.562499vw;
  line-height: 1.822919vw;
  width: 100%;
}
.textHeight p {
  line-height: 1.8vw;
  margin-bottom: 0rem;
}

//------------------------------------------------------------------------------------------MOBILE VERSION
@media only screen and (max-width: 1024px) and (min-width: 650px) {
  .ecommerce-page-redkite-will-box {
    max-width: 70% !important;
    margin: 30px 15% 0 15% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .ecommerce-page-redkite-will-box {
    box-shadow: $main-box-shadow;
    margin: 50px 0px 0px 0px;
    color: $main-title-and-text-color;
    max-width: 100%;
    padding: 0 20px;
    position: relative;
    background-color: $main-white-color;
  }
  .ecommerce-page-redkite-will-box-title {
    text-align: center;
    font-size: 25px;
    line-height: 17px;
    font-weight: bold;
    padding: 50px 0;
    z-index: 1;
  }

  .ecommerce-page-redkite-will-items-box {
    display: grid;

    width: 100%;
    padding-bottom: 25px;
    justify-content: left;
  }
  .ecommerce-page-redkite-will-items-text {
    display: grid;
    grid-template-columns: 33px auto;
    column-gap: 20px;
    align-items: center;
    padding: 0;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
  }

  .ecommerce-page-redkite-will-items-img {
    padding-bottom: 0%;
    padding-top: 0%;
    width: 50px;
  }
  .textHeight p {
    display: inline;
  }
}
