@import "../../../styles/_const.scss";

//-------------------------------------IMG with Right Box    main box
.ecommerce-second-box-with-IMG-and-box-box {
  margin-top: 9.2593vh;
}
.ecommerce-why-redkite-right-section {
  display: grid;
}
//-------------------------------------IMG with Right Box    IMG

.ecommerce-second-box-img {
  width: 100%;
}

//-------------------------------------IMG with Right Box    Right BOX

.ecommerce-second-box-box {
  box-shadow: $main-box-shadow;
  justify-self: end;
  background-color: $main-white-color;
  width: 43vw;
  margin-bottom: 6.4815vh;
  margin-top: -6.4815vh;
}
.ecommerce-second-box-title {
  font-weight: bold;
  font-size: 2.34375vw;
  line-height: 2.864585vw;
  color: $main-title-and-text-color;
  letter-spacing: 0px;
  padding: 9.2593vh 5.20835vw 4.62959vh 5.20835vw;
}
.ecommerce-second-box-text {
  color: $main-title-and-text-color;
  font-size: 1.302082vw;
  letter-spacing: 0px;
  padding: 0 6.20835vw 9.2593vh 5.20835vw;
  text-align: justify;
  width: 100%;
  line-height: 1.822917vw;
}

.blockFirst {
  margin-bottom: 12.14815vh;
  padding: 9.2593vh 5.72919vw 4.62965vh 5.72919vw;
}
.blockFirstWithoutFeatureList {
  padding: 9.2593vh 5.72919vw 0 5.72919vw;
}
.blockLast {
  margin-bottom: 4.62965vh;
  padding: 9.2593vh 5.72919vw 0 5.72919vw;
}
.blockFirst p,
.blockLast p {
  margin-bottom: 50px;
}
.blockLast:last-child {
  margin-bottom: -50px;
}
//------------------------------------------------------------------------------------------MOBILE VERSION

@media only screen and (max-width: 1024px) {
  .ecommerce-second-box-with-IMG-and-box-box {
    position: relative;
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 0px;
  }
  .ecommerce-second-box-box {
    box-shadow: $main-box-shadow;
    justify-self: center;

    background-color: $main-white-color;
    width: 89.34%;
    height: 100%;
  }

  .ecommerce-second-box-title {
    font-weight: bold;
    font-size: 20px;
    color: $main-title-and-text-color;
    letter-spacing: 0px;
    padding-bottom: 0px;
    padding: 35px 40px 20px 17px;
    text-align: left;
    line-height: 24px;
  }

  .ecommerce-second-box-text {
    color: $main-title-and-text-color;
    font-size: 14px;
    letter-spacing: 0px;

    text-align: justify;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px 19px 17px;
    line-height: 16px;
  }
  .ecommerce-second-box-img {
    height: 300px;
    object-fit: cover;
  }

  .blockFirst {
    margin-bottom: -50px;
    padding: 0 5.72919vw 4.62965vh 5.72919vw;
  }
  .blockFirstWithoutFeatureList {
    margin-bottom: -50px;
    padding: 0 5.72919vw 80px 5.72919vw;
  }
  .blockLast {
    margin-bottom: 50px;
    padding: 9.2593vh 5.72919vw 50px 5.72919vw;
  }
  .blockFirst p,
  .blockLast p {
    margin-bottom: 50px;
  }
  .blockLast:last-child {
    margin-bottom: -50px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 601px) {
  .ecommerce-second-box-box {
    box-shadow: $main-box-shadow;
    justify-self: center;

    background-color: $main-white-color;
    width: 71%;
    height: 100%;
  }
}
