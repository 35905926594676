@import "../../../styles/_const.scss";

.PopUpBoxHide {
  display: none;
}
.PopUpBoxText {
  color: #1c1b29;
  z-index: 6;
  position: fixed;
  box-shadow: 0px 10px 48px 4px black;

  margin-top: 20%; //-25%;
  width: 30%;
  padding: 30px 0 30px 0;
  text-align: center;
  margin-left: 35.5%; //29.5%;
  background-color: #fff;
}
.PopUpBoxText div {
  padding-top: 30px;
  font-size: 25px;
  font-weight: bold;
  white-space: pre-line;
}
.closeBox {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: -40px;
  margin-left: -16px;
}
.closeBoxIcon {
  cursor: pointer;
  width: 1vw;
}
@media only screen and (max-width: 1024px) {
  .PopUpBoxText {
    color: #1c1b29;

    position: fixed;
    box-shadow: 0px 10px 48px 4px black;
    margin-top: 30vh;
    width: 80%;
    z-index: 6;
    padding: 0px 0 50px 0;
    text-align: center;
    margin-left: 0;
    background-color: #fff;
    display: grid;

    justify-items: center;
  }
  .PopUpBox {
    display: flex;
    justify-content: center;
  }
  .PopUpBoxText div {
    padding-top: 35px;
    font-size: 18px;
  }
  .closeBox {
    margin-top: 0px;
    margin-left: -35px;
  }
  .closeBoxIcon {
    cursor: pointer;
    width: 20px;
    margin-top: -15px;
  }
  .icon {
    padding-top: 50px;
  }
}
