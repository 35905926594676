@import "../../styles/_const.scss";
//--------------------------------- header style on different pages
@mixin phoneLogo {
  background-repeat: no-repeat;
  background-size: cover;
  width: 2.6vw;
  height: 2.6vw;
  transition: 0.2s;
}

@mixin headerStyle {
  z-index: 6;
  height: 7.813vw;
  display: grid;
  grid-template-columns: auto auto;
  position: fixed;
  width: 100%;
  padding-left: 5%;
  font-weight: bold;
  align-items: end;
}

.mainHeaderLogo {
  width: 11.99vw;
  margin-right: 7vw;
}
.EngMainHeaderLogo {
  width: 11.99vw;
  margin-right: 10.5vw;
}
.white {
  @include phoneLogo;
  background-image: url(../../img/menuPhone.svg);
}
.white:hover {
  background-image: url(../../img/menuPhoneHover.svg);
  @include phoneLogo;
}

.black {
  @include phoneLogo;
  background-image: url(../../img/menuPhoneBlack.svg);
}
.black:hover {
  @include phoneLogo;
  background-image: url(../../img/menuPhoneBlackHover.svg);
}

.homeHeader {
  z-index: 6;
  padding-top: 1.88295vw;
  padding-bottom: 1vw;
  display: grid;
  grid-template-columns: auto auto;
  position: fixed;
  width: 100%;
  padding-left: 5%;
  font-weight: bold;
  background-color: #1c1b29;
  box-shadow: 0px 15px 18px -1px rgba(0, 0, 0, 0.75);
  transition: 500ms ease;
  align-items: unset;
}

.internalHeader {
  background-color: white;
  box-shadow: 0px 10px 30px #00000033;
  color: $main-white-color;
  z-index: 6;
  padding-top: 1.88295vw;
  padding-bottom: 1vw;
  display: grid;
  grid-template-columns: auto auto;
  position: fixed;
  width: 100%;
  padding-left: 5%;
  font-weight: bold;
  align-items: unset;
}

.transparent {
  background-color: transparent;
  box-shadow: none;
}
.transparentWhite {
  box-shadow: none;
}

//--------------------------Main Header Item

.main-header-item {
  font-size: 1.041669vw;
  line-height: 1.5625vw;
  margin-right: 2.083331vw;
  color: green;
  white-space: nowrap;
  a {
    text-decoration: none;
  }
}
.EngMainHeaderItem {
  font-size: 1.041669vw;
  padding-right: 2.083331vw;
  color: green;
  white-space: nowrap;
  a {
    text-decoration: none;
  }
}

//--------------------------Main Header Itema Box

.main-header-items-box-box {
  display: flex;
  align-items: center;
}

.inactive {
  text-decoration: none;
}
.inactive:hover {
  text-decoration: none;
}
.burgerMenu,
.MobileMenu {
  display: none;
}

// -------------------call button
@mixin callButton {
  font-size: 1.041669vw;
  line-height: 1.5625vw;
  font-weight: bold;
  border-radius: 30px;
  transition: 0.5s;
  background-color: white;
  outline: none !important;
}

.mainHeaderCallButtonBox {
  display: flex;
}
.mainHeaderCallBack {
  padding-left: 2.083331vw;
  align-self: center;
}

.whiteButton button {
  @include callButton;
  border: none;
  padding: 0.520835vw 1.25vw;
  color: #1c1b29;
}

.whiteButton button:hover {
  padding: 0.520835vw 1.25vw;
  color: white;
  background-color: #77767f91;
  transition: 0.5s;
}

.blackButton button {
  @include callButton;
  padding: 0.4166669vw 1.14585vw;
  color: #1c1b29;
  border: 2px solid #1c1b29;
}

.blackButton button:hover {
  padding: 0.4166669vw 1.14585vw;
  color: white;
  border: 2px solid transparent;
  background-color: #1c1b29;
  transition: 0.5s;
}

//------------------------------------------------------------------------------------------MOBILE VERSION
@media only screen and (max-height: 520px) and (min-height: 200px) {
  .mainHeaderLogo {
    width: 24.156vh !important;
    height: 8.16vh !important;
  }
  .homeHeader,
  .internalHeader {
    height: 18vh !important;
  }
  .asas img {
    width: 5vh !important;
  }
}
@media only screen and (max-width: 500px) and (min-width: 400px) {
  .homeHeader,
  .internalHeader {
    z-index: 6;
    height: 15vw !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: fixed;
    padding: 15px 20px 18px 4vw;
  }
}
@media only screen and (max-width: 399px) and (min-width: 250px) {
  .homeHeader,
  .internalHeader {
    z-index: 6;
    height: 22vw !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: fixed;
    padding: 15px 20px 18px 4vw;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 601px) {
  .asas {
    display: flex;
    justify-content: flex-end;
    margin-right: 4vw;
  }
  .asas img {
    width: 3.5vw;
  }

  .mainHeaderLogo {
    width: 20.156vw;
    height: 8.16vw;
  }
  .mainHeaderCallButtonBox {
    display: flex;
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 1024px) {
  .mainHeaderLogo {
    width: 24.156vw;
    height: 8.16vw;
    margin-top: -20px;
  }

  .main-header-items-box-box {
    display: none;
  }
  .homeHeader {
    z-index: 6;
    height: 12vw;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: fixed;
    padding: 15px 20px 18px 4vw;
  }
  .internalHeader {
    z-index: 6;
    height: 12vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: fixed;
    padding: 15px 20px 18px 20px;
  }

  .burgerMenu {
    margin-bottom: 5px;
    display: inline-block;
  }

  #menu {
    color: red;
  }

  // ----------button
  @mixin callButton {
    font-size: 14px;
    line-height: 28px;
    font-weight: bold;
    border-radius: 30px;
    transition: 0.5s;
    background-color: #d6d6d7;
    outline: none !important;
  }
  @mixin buttonProp {
    padding: 0px 20px; //2px 0 2px 15px;
    color: #1c1b29;
  }
  .mainHeaderCallBack {
    padding-left: 30px;
    align-self: center;
    margin-bottom: 40px;
  }

  .whiteButton button {
    @include callButton;
    @include buttonProp;
    border: none;
  }

  .whiteButton button:hover {
    @include callButton;
    @include buttonProp;
    border: none;
  }

  .blackButton button {
    @include callButton;
    padding: 0px 20px; // 0px 7.5px;
    border: 2px solid #1c1b29;
  }

  .blackButton button:hover {
    @include callButton;
    padding: 0px 0 0px 15px;
    border: 2px solid #1c1b29;
    color: #1c1b29;
  }

  //  phono logo
  @mixin phoneLogo {
    background-repeat: no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;
    transition: 0.2s;
  }

  .white {
    @include phoneLogo;
  }
  .white:hover {
    background-image: url(../../img/menuPhone.svg);
    @include phoneLogo;
  }

  .black {
    @include phoneLogo;
  }
  .black:hover {
    @include phoneLogo;
    background-image: url(../../img/menuPhoneBlack.svg);
  }
}

// languages
.EngCurrentLangStyle {
  color: #d10513;
  font-size: 1.041669vw;
  cursor: pointer;
  line-height: 1.5625vw;
}
.currentLangStyle {
  color: #d10513;
  font-size: 1.041669vw;
  cursor: pointer;
  line-height: 1.5625vw;
  margin-right: 1.5625vw;
}
.currentLangStyle img {
  padding-left: 0.4vw;
}
.arrowDown {
  width: 1.1579vw;
}
.chooseLangWrapper {
  position: relative;
}
.chooseLang {
  left: -8px;
  padding-top: 1.7vw;
  opacity: 0.9;
  padding-bottom: 1vw;
  text-align: left;
  padding-left: 10px;

  font-size: 1.041669vw;
  width: 5vw;
  position: absolute;
  color: white !important;
  cursor: pointer;

  bottom: -2.4vw;
  background-color: #1c1b293b;
}
.choosableLang {
  top: 0.3vw;
  position: relative;
  z-index: 1000;
  cursor: pointer;
  line-height: 1.5625vw;
}
.showzindex {
  position: relative;
  z-index: 100;
}

.classShow {
  visibility: visible;
}

.classHide {
  visibility: hidden;
}

.wr {
  color: #d10513;
  font-size: 1.041669vw;
  cursor: pointer;
  line-height: 1.5625vw;
  margin-right: 1vw;
  position: relative;
  top: 1vw;
  background-color: #473f4369;
  padding: 1.04166666667vw;
  margin-left: -1vw;
}
.wrN {
  color: #d10513;
  font-size: 1.041669vw;
  cursor: pointer;
  line-height: 1.5625vw;
  margin-right: 1vw;
  position: relative;
  top: 1vw;
  padding: 1.04166666667vw;
  margin-left: -1vw;
}
.wrN img {
  padding-left: 0.4vw;
}
.wr img {
  padding-left: 0.4vw;
}
.buttonLang {
  border: none;
  background-color: transparent;
  color: white;
  font-weight: bold;
  margin-left: -0.3vw;
  outline: none !important;
  margin-top: 0.2vw;
}
