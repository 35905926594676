.blocksBox {
  display: grid;
  grid-template-columns: 43% 43%;
  width: 100vw;
  column-gap: 30px;
}

.blocksBox div {
  text-align: justify;
  font-size: 1.302081vw;
  line-height: 1.822919vw;
}

@media only screen and (max-width: 1024px) and (min-width: 601px) {
  .blocksBox {
    display: grid;
    grid-template-columns: 100%;
    width: 80%;
    margin: 0 15% 0 10%;
  }
  .blocksBox div {
    padding: 0px 0 0 0;
    text-align: justify;
    font-size: 14px;
    line-height: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .blocksBox {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
  }
  .blocksBox div {
    padding: 0px 0 0 0;
    text-align: justify;
    font-size: 14px;
    line-height: 16px;
  }
}
