.imgWrapper {
  display: flex;
  justify-content: center;
  margin-top: 5vw;
}
.bird {
  position: absolute;
  transform: translate(109%, -71%);
}

.errorElementHeight {
  padding-top: 200px;
  height: 100vh;
}
.errorText {
  padding-top: 65px;
  color: #1c1b29;
  font-size: 30px;
  text-align: center;
  white-space: pre-line;
}
.get-in-touch-section-box-items-button-box {
  display: grid;
  padding: 35px 0;
}
.get-in-touch-section-box-items-button {
  border: 0.2vw solid #1c1b29;
  font-size: 20px;
  border-radius: 30px;
  background: transparent;
  color: #1c1b29;
  padding: 10px 40px;
  justify-self: center;
  font-weight: bold;
  text-transform: uppercase;
  outline: none;
}

.linkColor,
.linkColor:hover {
  text-decoration: none;
  color: white;
}

//------------------------------------------------------------------------------------------MOBILE VERSION

@media only screen and (max-width: 1024px) {
  .errorPic {
    width: 50%;
  }
  .bird {
    width: 30%;
    position: absolute;
    transform: translate(109%, -71%);
  }
  .errorText {
    padding-top: 30px;
    font-size: 14px;
  }

  .get-in-touch-section-box-items-button {
    border: 0.2vw solid #1c1b29;
    font-size: 14px;
    border-radius: 30px;
    background: transparent;
    color: #1c1b29;
    padding: 8px 30px;
    justify-self: center;
    font-weight: bold;
    text-transform: uppercase;
    outline: none;
  }
}
