@import "../../../../styles/_const.scss";

$background-blur: rgba(51, 51, 51, 0.4);

.link {
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition-delay: 0.8s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.8s ease, opacity 0.4s ease;
    transition-delay: opacity 0.2s ease;
  }

  .p {
    @include absolute-overlay;
    background-color: $background-blur;
    animation-name: blink-out;
    animation-duration: 0.4s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    color: white;
    font-weight: bold;
    display: flex;
    margin: 0;
    font-size: 1.822919vw;
    text-transform: uppercase;

    .span {
      display: block;
      margin: auto auto;
      text-align: center;
      line-height: 2.864585vw;
    }
  }

  &:global(.sectionActive) {
    img {
      opacity: 0;
    }

    .p {
      opacity: 0.3;
      animation-name: blink-in;
    }

    &:global(.active) {
      img {
        opacity: 1;
        transform: scale(1.1);
      }

      .p {
        opacity: 1;
      }
    }
  }
}

@keyframes blink-in {
  0% {
    background-color: $background-blur;
  }
  30% {
    background-color: #ffffff80;
  }
  100% {
    background-color: $background-blur;
  }
}

@keyframes blink-out {
  0% {
    background-color: $background-blur;
  }
  30% {
    background-color: #ffffff80;
  }
  100% {
    background-color: $background-blur;
  }
}
@media only screen and (max-width: 1024px) {
  .link {
    .p {
      padding: 0 0px;
      font-size: 14px;
      line-height: 0px;
      .span {
        display: block;
        margin: auto auto;
        text-align: center;
        line-height: 17px;
        padding-top: 17px;
      }
    }
  }
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}
