@import "../../../styles/_const.scss";

.solutions {
  background-image: url("../../../img/Bg_image.png");
  background-size: cover;
  border: 0;
  padding: 50px 0;

  @media screen and (min-width: $medium-width) {
    padding: 9.2593vh 0;
  }

  .heading,
  .text {
    color: white;
    margin: 0 40px 20px;
    text-align: center;

    @media screen and (min-width: $medium-width) {
      margin: 0 64px 20px;
      margin-bottom: 35px;
    }
  }

  .heading {
    font-size: 25px;
    font-weight: bold;
    @media screen and (min-width: $large-width) {
      font-size: 2.34375vw;
      line-height: 2.864585vw;
    }
  }
  .text p {
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .text {
    font-size: 14px;
    margin-bottom: 35px;

    @media screen and (min-width: $large-width) {
      font-size: 1.302081vw;
      line-height: 1.5625vw;
      margin-bottom: 6.4815vh;
    }
  }

  .grid {
    bottom: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    left: 0;
    position: relative;
    right: 0;
    top: 0;

    @media screen and (min-width: $large-width) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
@media only screen and (max-width: 600px) {
  .text p {
    line-height: 16px;
  }
}
