$main-white-color: #ffffff;
$main-grey-color: #767575;
$main-fontSize-for-text: 20px;
$main-fontSize-for-text-Ecommerce: 25px;
$main-fontSize-for-section-title: 45px;
$main-font-family: "Helvetica";
$main-box-shadow: 0px 10px 30px #00000033;
$main-title-and-text-color: #1c1b29;

// Media Screen resolutions
$medium-width: 540px;
$large-width: 1170px;

/**
 * Helper mixins
 */

// Absolutely placed child element 100% covering parent
@mixin absolute-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// font-size: 35px  ->  1.822919vw
// font-size: 20px   ->  1.041669vw;
// font-size: 45px  ->  2.34375vw;
// font-size: 25px -> 1.302081vw;
// font-size: 30px  1.562499vw;

// line-height:45px -> 2.34375vw;
//line-height: 22px->  1.145835vw;
//line-height:43px ->2.239585vw;
// line-height:30px -> 1.5625vw;
// line-height:35px -> 1.822919vw;
// line-height:55px -> 2.864585vw;

// height:100px -> 9.2593vh
// height:50px -> 4.62965vh

// margin: 40 -> 3.7037vh
// margin:80 -> 7.4074vh;
// margin:30 -> 2.7778vh;

// margin: 70 -> 3.64584vw;
// margin: 100 -> 5.20835vw
// margin: 104 -> 5.41669vw
// margin: 60 -> 3.125vw;
// margin: 20 -> 1.04166666667vw
// margin: 50 ->  2.60416666668vw

//1px -> 0.05208333333vw;
//1px -> 0.0925925vh
