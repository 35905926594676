@import "../../../../styles/_const.scss";

.wrapper {
  overflow: hidden;

  &,
  >img {
    @include absolute-overlay;
  }

  .background {

  }
  
  .animation {
    object-fit: cover;
    animation-duration: 10s;
    animation-fill-mode: forwards;
    opacity: 0;
    animation-name: foreground-animation;
  }
}

@keyframes foreground-animation {
  0% { opacity: 0; transform: scale(1) }
  10% { opacity: 1; }
  100% { opacity: 1; transform: scale(1.1) }
}