@import "../../../styles/_const.scss";

//----------------------------------------- GRadient Numbers List
.linkID {
  padding-top: 9.2593vh;
}
.emkaWords {
  padding-bottom: 3.5vh;
}
.ecommerce-customer-journey-box {
  background: #efefef;
  margin-top: 9.2593vh;
  padding-bottom: 4.62965vh;
}
.ecommerce-customer-journey-box-title {
  font-size: 2.34375vw;
  text-align: center;
  font-weight: bold;
  padding: 0vh 0 9.2593vh 0;
  line-height: 2.34375vw;
}

//------------------------------------------Gradient Number List    Buttons
.ecommerce-customer-journey-button-box {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  color: $main-title-and-text-color;
  padding-bottom: 4.62965vh;
}

.ecommerce-customer-journey-button-content {
  border: 3px solid #1c1b29;
  margin-top: 4.62963vh;
  border-radius: 30px;
  padding: 1.48148vh 2.864586vw;
  font-weight: bold;
  font-size: 1.041669vw;
  line-height: 1.041669vw;
  outline: none;
  transition: 0.5s;
}
.ecommerce-customer-journey-button-content:hover {
  background-color: #1c1b29;
  color: #efefef;
  transition: 0.5s;
}

//------------------------------------------Gradient Number List     Items

.ecommerce-customer-journey-items-box {
  display: flex;
  align-items: center;
  column-gap: 2.9vw;
  margin: 0 5vw 4.62963vh;
  background-color: $main-white-color;
  box-shadow: $main-box-shadow;
  border-radius: 110px 0 0 110px;
}

//------------------------------------------Gradient Number List   Left Part

.ecommerce-customer-journey-items-left-part-box {
  display: flex;
  border-radius: 101px;
  column-gap: 2vw;

  padding: 15px 4.6875vw;

  align-items: center;
}
.ecommerce-customer-journey-items-left-part-number {
  font-size: 4.166669vw;
  color: $main-white-color;
  font-weight: bold;
}
.ecommerce-customer-journey-items-left-part-text {
  font-size: 1.562499vw;
  color: $main-white-color;
  font-weight: bold;
  width: 20vw;
  line-height: 1.874999vw;
}
.ecommerce-customer-journey-items-left-part-text p {
  margin-bottom: 0rem;
}
//------------------------------------------Gradient Number List   Right Part

.ecommerce-customer-journey-items-right-part-box {
  width: 100%;
}

.ecommerce-customer-journey-items-right-part-text {
  font-size: 1.302081vw;

  padding-top: 1.5vh;

  padding-right: 3.2vw;
  line-height: 1.822919vw;
  text-align: justify;
}

.blocks {
  z-index: 1;
  position: relative;

  margin-left: 5vw;

  width: 90vw;

  grid-template-columns: 42% 50%;
  margin-bottom: 4.6296vh;
}

//------------------------------------------------------------------------------------------MOBILE VERSION

@media only screen and (max-width: 1024px) {
  .linkID {
    padding-top: 50px;
  }
  .ecommerce-customer-journey-box {
    margin-top: 50px;
  }
  .ecommerce-customer-journey-box-title {
    font-size: 25px;
    line-height: 17px;
    text-align: center;
    font-weight: bold;
    padding: 0px 0 50px 0;
  }
  .ecommerce-customer-journey-items-box {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    column-gap: 0px;
    width: 100%;
    margin: 0px;
    background: #efefef;
    background-color: $main-white-color;
    box-shadow: 0px 1px 0px -5px rgba(28, 27, 41, 1);
    border-radius: 0;
  }

  .ecommerce-customer-journey-items-left-part-box {
    display: grid;
    grid-template-columns: 21px auto;
    border-radius: 0px;
    width: 100%;
    column-gap: 15px;
    padding: 6.5px 20px;
    align-items: center;
    opacity: 0.8;
  }

  .ecommerce-customer-journey-items-left-part-number {
    font-size: 38px;
    color: $main-white-color;
    font-weight: bold;
  }
  .ecommerce-customer-journey-items-left-part-text {
    font-size: 14px;
    line-height: 16px;
    color: $main-white-color;
    font-weight: bold;
    width: 100%;
  }
  .ecommerce-customer-journey-items-box {
    background-color: #efefef;
  }
  .ecommerce-customer-journey-items-right-part-box {
    width: 90%;
    margin-left: 5%;

    box-shadow: $main-box-shadow;

    margin-bottom: 20px;
  }

  .ecommerce-customer-journey-items-right-part-text {
    font-size: 14px;
    line-height: 16px;
    padding: 20px 20px 24px 20px;
  }
  .ecommerce-customer-journey-button-box {
    display: grid;
    grid-template-columns: auto;
    padding-bottom: 50px;
    justify-content: center;
    color: $main-title-and-text-color;
    margin: 30px 64px 0px 64px;
  }

  .ecommerce-customer-journey-button-content {
    border: 2px solid #1c1b29;
    margin-top: 0px;
    border-radius: 30px;
    padding: 14px 39px;
    font-weight: bold;
    font-size: 14px;
    line-height: 10px;
  }

  .blocks {
    grid-template-columns: 100%;
    margin-bottom: 0px;
  }
}
