@import "../../styles/_const.scss";

//----------------Main Footer First
.main-footer-first-box {
  display: flex;
  //column-gap: 10.645835vw; //3.645835vw;
  column-gap: 10vw;
  background-color: #1c1b29;
  padding-bottom: 34px;
}

//---------------Main Footer Contact
.upWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .up {
    margin-right: -8vw;
  }
}

.contact-box {
  display: flex;
  align-items: center;
  margin-left: -1vw;
}
.socialIcons {
  width: 2.6041666665vw;
  margin-left: 1vw;
}
.socialIcons img:nth-child(2) {
  margin-left: 20px;
}

//----------------Main Footer First Subscribe

.main-footer-first-subscribe-box {
  margin-left: 5%;
}

//--------------Main Footer First Touch
.mainFooterFontSize p {
  margin: 0px;

  font-size: 1.041665vw;
}
.mainFooterFontSize {
  margin-top: -7px;
}
.main-footer-first-touch-box {
  color: $main-white-color;
}
.main-footer-first-text,
.main-footer-first-text-connect {
  font-size: 1.041669vw;
  color: $main-white-color;
  padding: 46px 0 20px 0;
  font-weight: bolder;
  line-height: 1.041669vw;
}

.goToTopIcon {
  background-image: url(../../img/UP.svg);
  cursor: pointer;
  width: 5vw;
  height: 5vh;
  transition: 0.2s;
  margin-top: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.2s;
  margin-top: 10px;
}
.goToTopIcon:hover {
  background-image: url(../../img/GoUpHover.svg);
  transition: 0.2s;
}

//-------------------------Main Footer Second

.footer {
  display: none;
  grid-template-columns: 45vw 14vw 12.5vw;
  background-color: #1c1b29;
  color: $main-white-color;
  font-size: 1.041669vw;
  font-weight: bold;
  justify-content: space-between;
  white-space: nowrap;
  padding: 36px 9.5vw 36px 5%;
}
.Engfooter {
  display: none;
}

//...........................subscribe part

.main-footer-input {
  width: 30.938vw;
  height: 3vw;
  border-radius: 30px;

  outline: none;
  border: none;
  padding-left: 15px;
  border: 1px solid transparent;
  padding-right: 13vw;
  color: $main-title-and-text-color;
  font-size: 1.041669vw;
}

.main-footer-button {
  height: 3vw;
  border-radius: 30px;
  outline: none !important;
  border: none;
  width: 11.875vw;
  position: absolute;
  text-align: center;
  background-image: linear-gradient(270deg, #c69100 0%, #cb00ac 100%);
  opacity: 0.8;
  color: white;
  font-weight: bold;
  font-size: 1.041669vw;
}
.main-footer-button:hover,
.main-footer-button:active {
  background-image: linear-gradient(
    270deg,
    #2b0f86 0%,
    #2b0f86 0%,
    #cc30b4 100%
  );
}

.main-footer-div {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.forMobileBox {
  display: none;
}

.linkColor,
.linkColorRUS2:hover,
.linkColorRUS:hover,
.linkColorRUS3:hover,
.linkColor:hover,
.linkColorRUS2,
.linkColorRUS,
.linkColorRUS3 {
  text-decoration: none;
  color: white;
}

.linkColorRUS2 {
  margin-left: -5vw;
}
.linkColorRUS3 {
  margin-left: 2vw;
}
.data {
  display: inline-flex;
}
.main-footer-redkite-logo-img {
  width: 7.448vw;
  height: 2.424vw;
  margin-left: 7.8125vw;
}
.t_p {
  display: flex;
  justify-content: space-between;
}
.t,
.d,
.p {
  display: flex;

  color: $main-white-color;
  font-size: 1.041669vw;
  font-weight: bold;
  margin-top: 100px;
}
.p {
  margin-left: 3.5vw;
}

.gic {
  display: block;
  width: 100%;
  position: absolute;
  margin-top: 60px;
  margin-left: -5%;
}
.feedback {
  margin-top: -20%;
  margin-left: 29.5%;
  z-index: 5;
  position: absolute;
}

//------------------------------------------------------------------------------------------MOBILE VERSION

@media only screen and (max-width: 1024px) {
  .goToTopIcon {
    margin-top: 20px;
    width: 30px;
  }
  .desktopScroolToUp {
    display: none;
  }
  .main-footer-first-box {
    display: grid;
    grid-template-columns: auto;
    background-color: #1c1b29;
    padding-bottom: 35px;
    border-bottom: 1px solid white;
  }

  .main-footer-redkite-logo-img {
    display: none;
  }
  .mainFooterFontSize p {
    margin: 0px;

    font-size: 13px;
    line-height: 17px;
  }
  .main-footer-div {
    position: relative;
    display: flex;
    width: 91%;
    justify-content: flex-start;
    margin-right: 0px;
  }
  .main-footer-input {
    display: flex;
    width: 91vw;
    height: 35px;
    border-radius: 30px;
    outline: none;
    border: none;
    padding-left: 15px;
    padding-right: 35%;
    color: $main-title-and-text-color;

    font-size: 14px;
  }

  .main-footer-button {
    height: 36px;
    border-radius: 30px;
    outline: none !important;
    border: none;
    width: 30%;
    position: absolute;
    opacity: 1;
    left: 64vw;
    text-align: center;
    background-image: linear-gradient(
      270deg,
      #c69100 0%,
      #c69100 0%,
      #cb00ac 100%
    ) !important;
    color: white;
    font-weight: bold;
    font-size: 13px;
  }
  .main-footer-first-subscribe-box {
    margin-left: 0;
  }

  .main-footer-first-touch-box,
  .main-footer-first-subscribe-box,
  .contact-box,
  .main-footer-first-text-connect {
    padding-left: 20px;
  }
  .contact-box {
    grid-template-columns: 30px 30px !important;
    margin-left: 0vw;
    column-gap: 15px;
  }
  .main-footer-first-text,
  .main-footer-first-text-connect {
    //this class we have also in "Main Footer First Subscribe"
    padding: 35px 0 15px 0;
    font-size: 14px;
    line-height: 16px;
  }
  .main-footer-first-text-connect {
    padding-left: 20px;
  }
  .mainFooterFontSize p {
    font-size: 14px;
    line-height: 17px;
  }
  .forMobileBox {
    display: flex;
    height: 47px;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    color: white;
    margin-top: 35px;
    padding: 0 20px;
  }

  .footer {
    display: grid;
    grid-template-columns: 40% 25%;
    background-color: #1c1b29;
    color: $main-white-color;
    font-size: 14px;
    font-weight: bold;
    display: grid;
    padding: 35px 20px;
    white-space: normal;
  }
  .socialIcons {
    width: 30px;
    height: 30px;
  }

  .contact-box div:nth-child(2) {
    margin-left: 5px;
  }
  .copyright {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
  }

  .privacy {
    grid-column-start: 1;
  }

  .terms {
    grid-column-start: 2;
    margin-left: -75px;

    margin-bottom: 15px;
  }

  .data {
    display: grid;
    grid-template-columns: auto;
  }
  .upWrapper {
    .up {
      margin-right: 8vw;
    }
  }
  .t_p {
    display: none;
  }
  .t,
  .d,
  .p {
    display: none;
  }
  .gic {
    display: none;
  }
  .feedback {
    margin-top: -50%;
    width: 80%;
    margin-left: -2%;
  }
}
@media only screen and (max-width: 600px) {
  .main-footer-button {
    left: 63.9vw !important;
    opacity: 1;
  }
  .main-footer-div {
    position: relative;
    display: flex;
    width: 94%;
    margin-right: 0px;
  }
}
