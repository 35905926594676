.boxWrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: flex-start;
  align-items: center;
  margin: 1.30208333334vw 0;
  a {
    display: flex;
  }
}
.align {
  align-self: center;
}
.text {
  margin-left: 1.04166666667vw;
  margin-right: 3.125vw;
  font-size: 1.041669vw;
  line-height: 1.041669vw;
  white-space: nowrap;
  .title {
    margin-bottom: 5px;
  }
  .title p,
  .subtitle p {
    margin-top: 0;
    margin-bottom: 0rem;
  }
}
.submitBox {
  display: flex;
  justify-content: center;

  .submitButton {
    padding: 1.148vw 2.864586vw;
    font-size: 1.041669vw;
    line-height: 1.041669vw;
    color: white;
    font-weight: bold;
    outline: none;
    border: none;
    background: linear-gradient(
      180deg,
      rgba(198, 145, 0, 1) 0%,
      rgba(203, 0, 172, 1) 100%
    );
    border-radius: 30px;
  }
  .pointerEvent {
    pointer-events: none;
  }
}
.submitButton:hover {
  background-image: linear-gradient(
    270deg,
    #2b0f86 0%,
    #2b0f86 0%,
    #cc30b4 100%
  );
}

.hide input[type="file"] {
  display: none;
}

.attachedFile {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../img/attached-attach-svgrepo-com.svg");
  width: 2.5vw;
  height: 2.7vw;
  cursor: pointer;

  margin-top: 10px;
}
.filesContainer {
  width: 300px;

  .fileDisplayWrapper {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .fileName {
    display: block;
    width: 260px;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .removeFile {
    cursor: pointer;
    margin-top: -5px;
  }
  .fileErrorContainer {
    color: red;
  }
}
.feedback {
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0px 10px 30px #00000033;
}
.loadingBg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #efefef;
  opacity: 0.1;
  z-index: 1000000000;
}
.loader {
  position: relative;
  left: 50%;
  margin-left: -40px;
  border: 10px solid #fff;
  border-radius: 50%;
  border-top: 10px solid #909090;
  top: 50%;
  margin-top: 0px;
  z-index: 999;
}
@media only screen and (max-width: 1024px) {
  .text {
    margin-left: 10px;
    margin-right: 60px;
    .title {
      margin-bottom: 2px;
    }
    .subtitle,
    .title {
      font-size: 12px;
      line-height: 12px;
    }
  }

  .submitBox {
    .submitButton {
      padding: 10px 25px;
      font-size: 14px;
      line-height: 14px;
    }
  }
  .submitButton:hover {
    background-image: linear-gradient(
      270deg,
      #2b0f86 0%,
      #2b0f86 0%,
      #cc30b4 100%
    );
  }

  .attachedFile {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../img/attached-attach-svgrepo-com.svg");
    width: 28.1px;
    height: 31px;
    cursor: pointer;

    margin-top: 10px;
  }
  .attachImg {
    width: 30px;
    margin-right: 10px;
  }
  .loader {
    display: none;
  }
}

@media only screen and (max-width: 341px) {
  .subtitle,
  .title {
    font-size: 10px !important;
    line-height: 10px !important;
  }
}
