@import "../../../styles/_const.scss";

//---------------------------------Work Section
@mixin arrowProp {
  width: 2.6vw;
  height: 2.6vw;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.2s;
}
.nextIcon {
  background-image: url(../../../img/IconRight.svg);

  @include arrowProp();
  margin-right: -55px;
  margin-top: -20px;
}
.nextIcon:hover {
  background-image: url(../../../img/IconRightHover.svg);
  @include arrowProp();
  opacity: 0.7;
}

.prevIcon {
  background-image: url(../../../img/IconLeft.svg);

  @include arrowProp();
  margin-left: -55px;
  margin-top: -20px;
}
.prevIcon:hover {
  background-image: url(../../../img/IconLeftHover.svg);
  @include arrowProp();
  opacity: 0.7;
}
.idWorks {
  padding: 9.2593vh 0 0 0;

  color: transparent;
}
.works-section-box {
  padding: 0 100px;
  background: #1c1b29;
  padding-bottom: 9.2593vh;
}
.works-section-box div {
  outline: none;
}

.imgWrapper {
  overflow: hidden;

  margin-right: 40px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.work-img {
  width: 100%;

  cursor: pointer;
  transition: 1.5s;
  opacity: 0.7;
  overflow: hidden;
}
.work-img:hover {
  transform: scale(1.5);
  transition: 1.5s;
  opacity: 1;
  overflow: hidden;
}

.works-section-box-title {
  padding: 9.2593vh 0;
  font-size: 2.34375vw;
  line-height: 2.864585vw;
  font-weight: bold;
  text-align: center;
  color: $main-white-color;
}

.work-img-title {
  font-size: 1.041668vw;
  color: $main-white-color;
  font-weight: bold;
  padding-left: 20px;
  margin-bottom: 0px;
  width: 70%;
  text-decoration: none;
}

.workSectionForMobileShow {
  display: none;
}
.showMoreButtonBox {
  display: none;
}

//------------------------------------------------------------------------------------------MOBILE VERSION
@media only screen and (max-width: 1024px) and (min-width: 851px) {
  .workSectionForMobileShow {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 49% 49%;
    padding: 0 70px;
  }

  .imgWrapper {
    margin: 0;
    overflow: hidden;
  }

  .works-section-box-title {
    font-size: 25px;
    line-height: 30px;
    padding: 50px 0;
  }
  .works-section-box {
    padding: 0px;
    padding-bottom: 10px;
    height: 100%;
  }
  .works-section-box div {
    outline: none;
  }

  .work-img-title {
    font-size: 14px;
    color: $main-white-color;
    padding-left: 6px;
    font-weight: bold;
    line-height: 16px;
    margin-top: 5px;
    margin-bottom: 40px;
    width: 90%;
    margin-left: -5px;
  }

  .workSectionForMobileHide {
    display: none;
  }
}
@media only screen and (max-width: 850px) {
  .workSectionForMobileShow {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 49% 49%;
    padding: 0 20px;
  }
  .idWorks {
    padding: 50px 0 0 0;
  }
  .imgWrapper {
    margin: 0;
    column-gap: 10px;
    overflow: hidden;
  }

  .works-section-box-title {
    font-size: 25px;
    line-height: 30px;
    padding: 50px 0;
  }
  .works-section-box {
    padding: 0px;
    padding-bottom: 10px;
    height: 100%;
  }
  .works-section-box div {
    outline: none;
  }
  .work-img {
    width: 100%;
    padding: 0px;
    cursor: pointer;
  }

  .work-img-title {
    font-size: 14px;
    color: $main-white-color;
    padding-left: 6px;
    font-weight: bold;
    line-height: 16px;
    margin-top: 5px;
    margin-bottom: 40px;
    width: 90%;
    margin-left: -5px;
  }

  .workSectionForMobileHide {
    display: none;
  }

  .showMoreButtonBox {
    display: grid;
    grid-template-columns: auto;
    margin-bottom: 40px;
    justify-content: center;
  }
  .showMoreButton {
    outline: none;
    color: $main-white-color;
    padding-bottom: 10px;
    border: 2px solid $main-white-color;
    margin-top: 10px;
    border-radius: 30px;
    padding: 14px 39px;
    line-height: 14px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    background-color: transparent;
  }
}
