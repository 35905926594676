.MobileMenu {
  display: none;
}

//------------------------------------------------------------------------------------------MOBILE VERSION
@media only screen and (max-height: 520px) and (min-height: 200px) {
  .MobileMenuFooterSocial {
    display: none;
  }
}
@media only screen and (max-height: 1024px) and (min-height: 521px) {
  .MobileMenuFooterSocial {
    padding-left: 10px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: 15% 30% 25%;
    padding-top: 40px;
    justify-items: start;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 601px) {
  .MobileMenuBox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .MobileMenu {
    width: 100vw;
    height: 100vh;
    position: relative;
  }

  .menuWrapperClass {
    display: block;
    z-index: 2;
  }
  .burgerMenu {
    position: absolute;
    top: 0;
  }
  .closeIcon {
    float: right;
    margin-top: 40px;
    margin-right: 20px;
  }
  .main-header-items-box-box {
    margin-top: 36px;
    margin-left: 20px;
    position: absolute;
  }
  .main-header-item {
    padding-bottom: 15px;
    font-size: 20px;
  }

  .inactive {
    text-decoration: none;
  }
  .inactive:hover {
    text-decoration: none;
  }
  //.....................................Mobile Menu footer
  .MobileMenuFooterBox {
    position: absolute;

    bottom: 0;

    width: 100%;

    background: #0f0f0f;
    opacity: 0.8;
  }

  .MobileMenuFooterTitle {
    font-weight: bold;
    font-size: 14px;
    color: white;
    position: absolute;
    padding-left: 20px;
    padding-top: 10px;
  }

  .socialIconsStyle {
    width: 30px;
    height: 30px;
  }
  .MobileMenuFooterSocialLogo {
    justify-self: end;
    width: 100px !important;
    height: 33px !important;
  }
}
@media only screen and (max-width: 600px) {
  .MobileMenuBox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .MobileMenu {
    width: 100vw;
    height: 100vh;
    position: relative;
  }

  .menuWrapperClass {
    display: block;
    z-index: 2;
  }
  .burgerMenu {
    position: absolute;
    top: 0;
  }
  .closeIcon {
    float: right;
    margin-top: 40px;
    margin-right: 20px;
  }
  .main-header-items-box-box {
    margin-top: 36px;
    margin-left: 20px;
    position: absolute;
  }
  .main-header-item {
    padding-bottom: 17px;
    font-size: 25px;
  }

  .inactive {
    text-decoration: none;
  }
  .inactive:hover {
    text-decoration: none;
  }
  //.....................................Mobile Menu footer
  .MobileMenuFooterBox {
    position: absolute;

    bottom: 0;

    width: 100%;

    background: #0f0f0f;
    opacity: 0.8;
  }

  .MobileMenuFooterTitle {
    font-weight: bold;
    font-size: 14px;
    color: white;
    position: absolute;
    padding-left: 20px;
    padding-top: 20px;
  }

  .socialIconsStyle {
    width: 30px;
    height: 30px;
  }
  .MobileMenuFooterSocialLogo {
    justify-self: end;
    width: 100px !important;
    height: 33px !important;
  }
}

//lang

.currentLangStyle {
  color: #d10513;
  font-size: 20px;

  font-weight: bold;

  margin-top: 20px;
}

.arrowDown {
  margin-left: 8px;
  width: 18px;
  height: 11px;
}
.chooseLangWrapper {
  position: relative;
}
.chooseLang {
  left: -8px;
  margin-top: 15px;
  font-size: 20px;

  color: white !important;
}

.classShow {
  visibility: visible;
}

.classHide {
  visibility: hidden;
}

.wr {
  color: #d10513;
  font-size: 20px;
  cursor: pointer;
  margin-right: 1.5625vw;
  position: relative;
  top: 1vw;
}
.wrN {
  color: #d10513;
  font-size: 20px;
  cursor: pointer;

  margin-right: 1.5625vw;
  position: relative;
  top: 1vw;
}
.wrN img {
  padding-left: 0.4vw;
}
.wr img {
  padding-left: 0.4vw;
}
.buttonLang {
  border: none;
  background-color: transparent;
  color: white;
  font-weight: bold;
  margin-left: -1.6vw;
  outline: none !important;
  margin-top: 0.2vw;
}

.buttonLangBlack {
  border: none;
  background-color: transparent;
  color: #1c1b29;
  font-weight: bold;
  margin-left: -1.6vw;
  outline: none !important;
  margin-top: 0.2vw;
}
@media only screen and (max-height: 400px) {
  .menuItemWrapper {
    // border: 1px solid red;
    overflow-y: scroll;
    height: 35vh;
  }
  .buttonLang,
  .buttonLangBlack {
    margin-left: -7px;
  }
}
.bottomLogo {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-bottom: 20px;
}
