//.............................. Staff
.mainBox {
  width: 100%;
  background-color: #efefef;
  padding: 9.2593vh 0;
}

//.............................. Text

.textBox {
  color: #1c1b29;
  margin-bottom: 9.2593vh;
  text-align: center;
  .title {
    font-size: 2.34375vw;
    line-height: 2.864585vw;
    margin-bottom: 4.62965vh;
    font-weight: bold;
  }
  .text {
    font-size: 1.041669vw;
    line-height: 1.5625vw;
    margin: 0 30%;
  }
}

//.............................. Staff Box
.staffBox {
  color: #1c1b29;
  .staffBoxTitle {
    font-size: 1.562499vw;
    line-height: 2.864585vw;
    text-align: center;
    font-weight: bold;
    margin-bottom: 9.2593vh;
  }
  .staffBoxForItems {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    column-gap: 6.510425vw;
    row-gap: 6.510425vw;
  }
}

//.............................. Staff Box item
.toAlign {
  display: grid;

  justify-items: center;
}
.staffItemBox {
  // justify-content: center;
  .picture {
    display: flex;
    justify-content: center;
    // width: 11.417vw;
    // height: 11.417vw;
    border-radius: 100%;
    width: 10.417vw;
    height: 10.417vw;
    // margin-left: 50px;
    overflow: hidden;
    .picProp {
      width: 10.417vw;
      height: 10.417vw;
      border-radius: 100%;
      transition: 1s;

      &:hover {
        transform: scale(1.2);
        transition: 1s;
      }
    }
  }
  .name {
    text-align: center;
    margin-top: 2.7778vh;
    font-size: 1.562499vw;
    line-height: 2.864585vw;
    font-weight: bold;
    white-space: nowrap;
  }
  .position {
    text-align: center;
    font-size: 1.041669vw;
    line-height: 1.041669vw;
  }
  .link {
    color: #1c1b29;
    transition: 1s;
  }
  .link:hover {
    color: #d10513;
    transition: 1s;
  }
  .name p,
  .position p {
    margin-top: 0;
    margin-bottom: 0rem;
  }
}

//.......................Mobile version
@media only screen and (max-width: 1024px) {
  //.............................. Staff
  .mainBox {
    padding: 21px 0 50px 0;
  }
  //.............................. Text
  .textBox {
    margin-bottom: 50px;

    .title {
      font-size: 25px;
      line-height: 25px;
      margin-bottom: 20px;
    }
    .text {
      font-size: 14px;
      line-height: 20px;
      margin: 0 20%;
    }
  }
  //.............................. Staff Box
  .staffBox {
    .staffBoxTitle {
      font-size: 18px;
      line-height: 55px;
      margin-bottom: 50px;
    }

    .staffBoxForItems {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 35px;
      row-gap: 30px;
    }
  }

  //.............................. Staff Box item
  .staffItemBox {
    .picture {
      .picProp {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      margin-top: 10px;
      font-size: 14px;
      line-height: 16px;
    }
    .position {
      margin-bottom: 5px;
      font-size: 10px;
      line-height: 11px;
    }
  }
}
