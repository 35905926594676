.TextWrapperBox {
  .title {
    margin-bottom: 1.04166666667vw;
  }
  .subTitle {
    margin-bottom: 2.60416666668vw;
  }
  .subTitle p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  li {
    line-height: 1.822919vw;
  }
  dl,
  ol,
  ul {
    margin-left: -1rem;
  }
}

@media only screen and (max-width: 1024px) {
  .TextWrapperBox {
    font-size: 14px;
    line-height: 14px;
    li {
      line-height: 25px;
    }
    .title {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 20px;
    }
    .subTitle {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 35px;
    }
  }
}
