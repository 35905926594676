@import "../../../styles/_const.scss";

//--------------------------------Cover Box Text
.ecommerce-page-cover-box-text-box {
  padding-left: 6vw;
  padding-top: 15.625vw;
  padding-bottom: 7.8125vw;
  color: $main-white-color;
}
.ecommerce-page-cover-box-text-box p {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
}
.ecommerce-page-cover-box-text-title {
  font-size: 4.166665vw;
  line-height: 4.583331vw;
  font-weight: bold;
}
.ecommerce-page-cover-box-text-title p {
  font-weight: bold;
}
.ecommerce-page-cover-box-text-subtitle {
  margin-top: 15px;
  font-size: 1.822914vw;
  line-height: 2.34375vw;
  width: 53vw;
  font-weight: 400;
}
.ecommerce-page-cover-box-text-subtitle ul {
  list-style: none;
  padding-inline-start: 0px;
}

.ecommerce-page-header-buttons {
  border: 3px solid #efefef;
  font-size: 1.822914vw;
  padding: 1.85185vh 1.864586vw;
  color: #efefef;
  /*color: #1c1b29;*/
  border-radius: 35px;
  background: transparent;
  outline: none;
  font-weight: bold;
  transition: 0.2s;
  line-height: 1.041669vw;
}

.ecommerce-page-header-buttons:hover {
  background-color: #efefef;
  color: #1c1b29;
  transition: 0.2s;
}
.ecommerce-page-header-buttons:focus {
  outline: none;
}

.ecommerce-page-header-buttons-box {
  display: flex;
  justify-content: start;
  column-gap: 2.6vw;
  margin-top: 15px;
}

//------------------------------------------------------------------------------------------MOBILE VERSION

@media only screen and (max-width: 850px) {
  .ecommerce-page-cover-box-text-box {
    padding-left: 0vw;

    padding-top: 150px;
    padding-bottom: 100px;
    color: $main-white-color;
  }

  .ecommerce-page-cover-box {
    width: 100%;
    height: 338px;
    object-fit: cover;

    width: 100%;

    margin-top: 80px;
  }
  .ecommerce-page-cover-box-text-title {
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
    text-align: left;
    padding-left: 20px;
    padding-right: 21px;
  }
  .ecommerce-page-cover-box-text-subtitle {
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    text-align: left;
    padding-left: 20px;
    padding-right: 21px;
  }
  .ecommerce-page-cover-box-text-subtitle p {
    display: inline;
  }

  .ecommerce-page-header-buttons {
    font-size: 14px;
    line-height: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .ecommerce-page-header-buttons-box {
    padding-left: 20px;
  }


}
