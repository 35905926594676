//.....................Call Back Form
.mainBoxNone {
  display: none;
}
.mainBox {
  display: flex;
  height: 100vh;
  width: 100%;
  z-index: 10;
  position: fixed;
  backdrop-filter: blur(16px);
}
.formBox {
  width: 36.9799vw;
  margin: auto;
  background-color: white;
  box-shadow: 0px 10px 50px 30px rgba(0, 0, 0, 0.2);
}
.close {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  margin-top: 20px;
  padding-right: 20px;
  .closeIcon {
    cursor: pointer;
    width: 20px;
  }
}
//.....................Text
.texts {
  color: #1c1b29;
  text-align: center;
  .title {
    font-size: 1.822919vw;
    line-height: 2.239585vw;
    font-weight: bold;
    white-space: pre-line;
    margin-bottom: 20px;
    margin-top: 3.7037vh;
  }
  .title p {
    margin-bottom: 0.6rem;
  }
  .titleTimeZone {
    font-size: 1.041669vw;
    line-height: 1.145835vw;
    margin-bottom: 10px;
  }
}

//.....................Fields
.inputFormBox {
  display: grid;
  justify-content: center;
}
.labelName {
  font-size: 1.041669vw;
  color: #1c1b29;
  margin-top: 2.7778vh;
}
.labelName span {
  color: #d10513;
}
.inputProps {
  height: 5.556vh;
  width: 24.584vw;
  font-size: 1.041669vw;
  outline: none;
}
.iconSize {
  width: 3.2vw;
  cursor: pointer;
}
.iconSize:last-child {
  margin-left: 20px;
}

.iconsBox {
  margin: 2.7778vh 0 4.6296vh 0;
}

.submitBox {
  display: flex;
  justify-content: center;
  margin-bottom: 4.375vw;
  .submitButton {
    padding: 1.48148vh 2.864586vw;
    font-size: 1.041669vw;
    line-height: 1.041669vw;
    color: white;
    font-weight: bold;
    outline: none;
    border: none;

    background: linear-gradient(
      180deg,
      rgba(198, 145, 0, 1) 0%,
      rgba(203, 0, 172, 1) 100%
    );
    border-radius: 30px;
  }
}
.submitButton:hover {
  background-image: linear-gradient(
    270deg,
    #2b0f86 0%,
    #2b0f86 0%,
    #cc30b4 100%
  );
}
.feedback {
  margin-top: -25%;
  margin-left: -2.5%;
}
//...............................mobile
@media only screen and (max-width: 600px) {
  .formBox {
    width: 80%;
  }
  .texts {
    .title {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 10px;
    }
    .titleTimeZone {
      font-size: 14px;
      line-height: 14px;
    }
  }
  .labelName {
    font-size: 14px;
    color: #1c1b29;
    margin-top: 2.7778vh;
  }
  .inputProps {
    height: 40px;
    width: 240px;
    font-size: 14px;
    outline: none;
  }
  .iconSize {
    width: 40px;
    cursor: pointer;
  }
  .iconSize:last-child {
    margin-left: 10px;
  }

  .submitBox {
    margin-bottom: 35px;
    .submitButton {
      padding: 15px 25px;
      font-size: 14px;
      line-height: 14px;
      color: white;
    }
  }
  .iconsBox {
    margin: 2.7778vh 0 30px 0;
  }
  .feedback {
    margin-top: -100%;
    margin-left: 0%;
    width: 80%;
    font-size: 14px;
  }
}
