@import "../../../styles/_const.scss";

//------------------------------Contact Us
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.emailUs {
  height: 7.5vw;
  color: transparent;
  display: block;
}
.ecommerce-contact-us-box {
  margin: 0 5.7vw 6.5vw;
}
.ecommerce-contact-us-title {
  color: $main-title-and-text-color;
  font-size: 2.34375vw;
  line-height: 2.864584vw;
  font-weight: bold;
  width: 55vw;
  margin-bottom: 4.62965vh;
}

.ecommerce-contact-us-form-structure {
  display: grid;
  grid-template-rows: auto auto;

  > div {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 0.8vw;
  }
}

.ecommerce-contact-us-form-structure-name,
.ecommerce-contact-us-form-contact-and-email {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.8vw;
}

.ecommerce-contact-us-form-company-name {
  display: grid;
  grid-template-columns: auto;
  column-gap: 0.8vw;
}
.ecommerce-contact-us-form-message {
  display: grid;
  grid-template-columns: auto;
}
.gridColumnOne {
  grid-column-start: 1;
  width: 50%;
  margin-bottom: 25px;
}
.ecommerce-contact-us-form-NDA-and-atach {
  display: grid;
  grid-template-columns: auto auto;
}
.leftMargin {
  margin-left: 0 !important;
}
.attacheMargin {
  margin-left: -21.5vw;
}
.marginLeftStyle {
  margin-left: -16px;
  width: 1.823vw;
}

.form-name,
.form-number-and-email,
.form-company-name {
  width: 100%;
  height: 40px;
  border: 1px solid $main-title-and-text-color;
  outline: none;
  font-size: 1.041669vw;
  line-height: 1.041669vw;
  padding-left: 15px;
  -webkit-appearance: none;
}
.ndaStyle {
  margin-left: 2vw;
}
.form-message {
  width: 100%;
  height: 90.2%;
  resize: none;
  padding: 15px;
  font-size: 1.041669vw;
  line-height: 1.041669vw;
  border: 1px solid $main-title-and-text-color;
  outline: none;
}
.ecommerce-contact-us-box label {
  font-size: 1.041669vw;
  line-height: 1.041669vw;
  margin-bottom: 0px;
}

.ecommerce-contact-us-form-structure-name,
.ecommerce-contact-us-form-contact-and-email,
.ecommerce-contact-us-form-company-name {
  margin-bottom: 27px;
}
.ecommerce-contact-us-form-NDA-and-atach label {
  padding-left: 0.9vw;
  color: $main-title-and-text-color;
}
.ecommerce-contact-us-form-NDA-and-atach label sup {
  font-size: 0.626vw;
  color: #808080;
}

.ecommerce-contact-us-form-NDA-and-atach {
  margin-top: 2.2vh;
  margin-bottom: 2.2vh;
  margin-left: 15px;
}

#hide input[type="file"] {
  display: none;
  margin: 10px;
}

.attached-file {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../img/attached-attach-svgrepo-com.svg");
  width: 1.6vw;
  height: 1.75vw;
  cursor: pointer;
}
.attached-file-text {
  width: 250px;
  color: $main-title-and-text-color;

  line-height: 1.041669vw;
  padding-top: 5px;
  margin-left: 25px;
}
.ecommerce-contact-us-form-button {
  color: $main-title-and-text-color;
  border: 3px solid $main-title-and-text-color;
  font-size: 1.041669vw;
  line-height: 1.041669vw;
  border-radius: 30px;
  background: white;
  font-weight: bold;
  padding: 1.48148vh 2.864586vw;
  outline: none !important;
  transition: 0.5s;
}
.ecommerce-contact-us-form-button:hover {
  background-color: #1c1b29;
  color: #efefef;
  transition: 0.5s;
}

.info-icon {
  width: 14px;
  margin-bottom: 10px;
  margin-left: 7px;
}

.form-group input {
  display: none;
}

.form-group label {
  position: relative;
  cursor: pointer;
  margin-left: -35px;
}
.toHide {
  display: none;
  margin-left: -20px;
}
.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;

  display: inline-block;

  vertical-align: middle;

  margin-right: 5px;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: -11px;
  left: 44px;
  width: 17px;
  height: 50px;
  border: solid #1c1b29;
  border-width: 0px 4px 4px 0;
  border-radius: 3px;
  transform: rotate(45deg);
}
.fileErrorContainer {
  color: red;
}
.filesContainer {
  width: 300px;
  .fileDisplayWrapper {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .fileName {
    display: block;
    width: 260px;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .removeFile {
    cursor: pointer;
    margin-top: -5px;
  }
}
.afterSendSuccess {
  color: green;
  padding-top: 10px;
  text-align: center;
}
.afterSendError {
  color: red;
  padding-top: 10px;
  text-align: center;
}
.formActionButtons {
  display: flex;
  align-items: center;
  margin-top: 50px;
}
.showInMobile {
  display: none;
}
.form {
  position: relative;
}
.loadingBg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #efefef;
  opacity: 0.1;
}
.feedback {
  margin-top: -30% !important;
  margin-left: 34% !important;
  z-index: 5 !important;
  position: absolute !important;
}
//------------------------------------------------------------------------------------------MOBILE VERSION

@media only screen and (max-width: 1024px) {
  .attacheMargin {
    margin-left: -5%;
  }
  .marginLeftStyle {
    margin-left: -1px;
    width: 30px;
  }
  .ecommerce-contact-us-box {
    margin: 0px 0px 50px 0px;
  }
  .ecommerce-contact-us-title {
    color: $main-title-and-text-color;
    font-size: 25px;
    line-height: 30px;
    font-weight: bold;
    width: 90%;

    margin-bottom: 50px;
    margin-top: 50px;
    margin-left: 5%;
  }
  .ecommerce-contact-us-form-structure-name,
  .ecommerce-contact-us-form-contact-and-email {
    display: grid;
    grid-template-columns: auto;
    width: 90%;
    margin-left: 5%;
    row-gap: 10px;
  }
  .ecommerce-contact-us-form-contact-and-email {
    margin-top: 15px;
  }

  .ecommerce-contact-us-form-structure {
    display: grid;
    grid-template-columns: auto;

    > div {
      display: grid;
      grid-template-columns: auto;
    }
  }
  .ecommerce-contact-us-form-message {
    display: grid;
    grid-template-columns: auto;
    width: 90%;
    height: 100%;
    margin-left: 5%;
    margin-top: 15px;
  }
  .ecommerce-contact-us-form-company-name {
    display: grid;
    grid-template-columns: auto;
    width: 90%;
    margin-top: 15px;
    margin-left: 5%;
  }
  .ecommerce-contact-us-form-NDA-and-atach {
    display: grid;
    grid-template-columns: auto;
    width: 90%;
    margin-left: 5%;
  }

  .form-group input {
    display: none;
  }

  .form-group label {
    margin-left: -20px;
  }

  .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;

    display: inline-block;

    vertical-align: middle;
    margin-left: 0px;
    margin-right: 0px;
  }
  .form-group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: -11px;
    left: 44px;
    width: 17px;
    height: 50px;
    border: solid #1c1b29;
    border-width: 0px 4px 4px 0;
    border-radius: 3px;
    transform: rotate(45deg);
  }
  .ndaStyle,
  .attachStyle {
    font-size: 14px;
  }
  .ndaStyle {
    margin-left: 25px;
  }
  .attached-file {
    background-image: url("../../../img/attached-attach-svgrepo-com.svg");
    width: 23px;
    height: 25px;
    margin-left: 5%;
    cursor: pointer;
    margin-top: 15px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .attached-file-text {
    margin-left: 30px;
  }
  .ecommerce-contact-us-box label {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0px;
  }

  .ecommerce-contact-us-form-structure-name,
  .ecommerce-contact-us-form-contact-and-email {
    margin-bottom: 2px;
  }
  .form-name,
  .form-number-and-email,
  .form-company-name {
    width: 100%;
    height: 35px;
    border: 1px solid $main-title-and-text-color;
    outline: none;
    font-size: 14px;
    line-height: 14px;
  }
  .hideInMobile {
    display: none;
  }
  .showInMobile {
    display: grid;
  }
  .form-message {
    width: 100%;
    height: 135px;
    resize: none;
    font-size: 14px;
    line-height: 14px;
    border: 1px solid $main-title-and-text-color;
    outline: none;
  }
  .formActionButtons {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
  }
  .ecommerce-contact-us-form-button {
    padding: 10px 40px;
    font-size: 14px;
    line-height: 16px;
    border: 2px solid $main-title-and-text-color;
  }
  .info-icon {
    width: 10px;
    margin-bottom: 2px;
    margin-left: 4px;
  }
  .feedback {
    margin-top: -80% !important;
    width: 80% !important;
    position: absolute !important;
    margin-left: 2% !important;
  }
}
