@import "../../../styles/_const.scss";

//---------------------------First Section

//.bg-video {
//width: 100vw;
//height: 100vh;
//background-image: url("../../../img/BgVideo_Pic.png");
//}
.bg-video {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-image: url("../../../img/BgVideo_Pic.png");
}
.videoOption {
  object-fit: fill;

  width: 100vw;
  height: 100vh;
}

.first-section {
  z-index: 5;
  position: relative;
}

.bg-gradient {
  width: 100% !important;
  height: 100vh !important;
  position: absolute;
  z-index: 2;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7662640056022409) 10%,
    rgba(56, 10, 70, 0.7662640056022409) 100%
  );
}
@supports (-ms-ime-align: auto) {
  .bg-gradient {
    opacity: 0;
  }

  .videoOption {
    position: relative;
    max-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .first-section-partners-box1 {
    display: none;
  }
}
.first-section-play-button {
  display: none;
  width: 5.2059vw;
  cursor: pointer;
}
//------------------------------First Section Partners

.first-section-partners-box {
  position: absolute;
  width: 100% !important;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: #0f0f0f;
  display: flex;
  z-index: 3;
  color: $main-white-color;
  align-items: center;
  padding: 2.37% 0;
}

.first-section-partners img {
  margin-left: 60px;
}
.first-section-partners img:nth-child(1) {
  width: 9.636vw;
  height: 3.125vw;
}
.first-section-partners img:nth-child(2) {
  width: 10.521vw;
  height: 3.125vw;
}
.first-section-partners img:nth-child(3) {
  width: 12.006vw;
  height: 1.663vw;
}
.first-section-partners-text {
  padding-left: 5%;
  font-size: 1.041669vw;
  font-weight: bold;
}

//------------------------First Section Text Info

.first-section-text-info-box {
  z-index: 2;
  display: grid;
  grid-template-columns: 75% auto;
  align-items: center;
  position: absolute;
  right: 0;
  left: 5%;
  bottom: 20%;
  top: 10vw; //15vh;
}

.first-section-text {
  width: 66.929%;

  font-size: 3.125vw;
  line-height: 3.75vw;
  text-align: left;
  color: $main-white-color;
  font-weight: bold;
  margin-top: 0%;
}

.first-section-second-text {
  width: 74.048%;
  font-size: 1.822919vw;
  line-height: 2.34375vw;
  padding-top: 20px;
  color: $main-white-color;
  margin-top: 0%;
}
.first-section-second-text p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.first-section-button {
  background-color: transparent;
  border: 3px solid #ffffff;
  border-radius: 30px;
  padding: 1.2037vh 2.864585vw;
  margin-top: 6.4815vh;
  font-size: 1vw;
  color: $main-white-color;
  text-transform: uppercase;
  letter-spacing: 0px;
  outline: none;
  transition: 0.5s;
  font-weight: bold;
}

.first-section-button:hover {
  background-color: #77767f91;
  transition: 0.5s;
}

//------------------------------------------------------------------------------------------MOBILE VERSION

@media only screen and (max-width: 600px) {
  .first-section-play-button {
    display: none;
  }
  .bg-video {
    background-image: url("../../../img/BgVideo_mobile.png");
  }
  .videoOption {
    display: none;
  }
  .first-section-text-info-box {
    z-index: 2;
    display: grid;
    grid-template-columns: auto;
    right: 0;
    left: 0;
    margin-left: 12px;
    margin-right: 12px;
    top: 20%;
  }
  .first-section-second-text {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    padding-top: 20px;
    color: $main-white-color;
    margin-top: 0%;
  }
  .first-section-text {
    width: 100%;
    text-align: left;
    font-size: 30px;
    line-height: 36px;
  }

  .first-section-button {
    background-color: transparent;
    border: 2px solid #ffffff;
    padding: 6px 40px;

    margin-top: 35px;
    font-size: 14px;
    color: $main-white-color;
    text-transform: uppercase;
    letter-spacing: 0px;
    outline: none;
  }

  .first-section-play-button-box {
    position: relative;
    display: grid;
    justify-content: center;
    justify-items: center;
  }

  .first-section-partners-box {
    position: absolute;
    width: 100% !important;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background: #0f0f0f;
    display: grid;
    z-index: 3;
    color: $main-white-color;
    font-size: 14px;
    padding: 15px 0;
  }
  .first-section-partners-img {
    width: 80px;
    height: 27px;
    padding-left: 0px;
    margin-left: 30px;
  }

  .first-section-partners img {
    margin-left: 30px;
  }
  .first-section-partners img:nth-child(1) {
    width: 26.67vw;
    height: 8.63vw;
  }
  .first-section-partners img:nth-child(2) {
    width: 26.67vw;
    height: 8.63vw;
  }
  .first-section-partners img:nth-child(3) {
    width: 20vw;
    height: 5vw;
  }
  .first-section-partners-text {
    padding-left: 5%;
    font-size: 1.041669vw;
  }
  .first-section-partners {
    padding-top: 20px;
    margin-left: -10px;
  }
  .first-section-partners-text {
    padding-left: 5%;
    font-size: 14px;
    line-height: 17px;
  }
}
