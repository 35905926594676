@import "../../../styles/_const.scss";

//-----------------------------Left And  Right Sections
.emailUs {
  padding-bottom: 3vh;
}
.ecommerce-success-stories-box {
  background-color: #efefef;
  position: relative;
}
.ecommerce-success-stories-title {
  line-height: 2.864585vw;
  font-size: 2.34375vw;
  text-align: center;
  font-weight: bold;
  padding: 6.7vh 0;
  color: $main-title-and-text-color;
}

//-----------------------------Left And  Right Sections     Box
.ecommerce-success-stories-box-box {
  margin-bottom: 15.7407vh;
}

.ecommerce-success-stories-box-info-box {
  display: grid;
}

//-----------------------------Left And  Right Sections     Buttons

.ecommerce-success-stories-button-box {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  color: $main-title-and-text-color;
  padding-bottom: 9.2593vh;
}

.ecommerce-success-stories-button-text {
  border: 0.2vw solid #1c1b29;
  border-radius: 30px;
  padding: 1.3963vh 2.864586vw;
  font-weight: bold;
  font-size: 1.041669vw;
  line-height: 1.041669vw;
  outline: none;
  transition: 0.5s;
}
.ecommerce-success-stories-button-text:hover {
  background-color: #1c1b29;
  color: #efefef;
  transition: 0.5s;
}

//-----------------------------Left And  Right Sections      Sub box

.ecommerce-success-stories-sub-box-box {
  padding-top: 9.2593vh;
  padding-left: 5.9vw;
  padding-right: 6.2vw;
  background: $main-white-color;
  box-shadow: $main-box-shadow;
}
.left-right-box:nth-child(even) {
  background-color: $main-white-color;
  box-sizing: border-box;
}
.left-right-box:nth-child(odd) {
  background-color: $main-white-color;
  width: 57vw;
  margin-top: 6.4815vh;
  margin-bottom: -6.4815vh;
}

.ecommerce-success-stories-box-box:nth-child(even) .left-right-box {
  justify-self: end;
}

//-----------------------------Left And  Right Sections      Sub box Buttons

.ecommerce-success-stories-sub-box-button {
  border-radius: 30px;
  border: 0.2vw solid $main-title-and-text-color;
  padding: 1.48148vh 2.864586vw;
  background-color: $main-white-color;
  font-size: 1.041669vw;
  line-height: 1.041669vw;
  font-weight: bold;
  margin-bottom: 9.2593vh; //7.2vh;;
  outline: none;
  transition: 0.5s;
  text-transform: uppercase;
}
.ecommerce-success-stories-sub-box-button:hover {
  background-color: #1c1b29;
  color: #efefef;
  transition: 0.5s;
}

//-----------------------------Left And  Right Sections      Sub box Text
.commerce-success-stories-sub-box-title {
  font-weight: bold;
  font-size: 2.34375vw;
  line-height: 2.864585vw;
  color: $main-title-and-text-color;
  margin-bottom: 4.6296vh;
}

.commerce-success-stories-sub-box-Text {
  font-size: 1.302085vw;
  line-height: 1.822919vw;
  color: $main-title-and-text-color;
  padding-bottom: 4.62965vh;
}

//------------------------------------------------------------------------------------------MOBILE VERSION

@media only screen and (max-width: 1024px) {
  .ecommerce-success-stories-box {
    background-color: #efefef;
  }

  .ecommerce-success-stories-title {
    display: block;
    padding: 50px 0;
    font-size: 25px;
    line-height: 17px;
    text-align: center;
    font-weight: bold;

    color: $main-title-and-text-color;
  }
  .ecommerce-success-stories-box-box {
    margin-bottom: 100px;
  }

  .ecommerce-success-stories-box-box:nth-child(even) .left-right-box {
    justify-self: start;
    margin-left: 5%;
  }
  .left-right-box:nth-child(odd) {
    background-color: $main-white-color;
    width: 90%;

    margin-left: 5%;
  }

  .commerce-success-stories-sub-box-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: $main-title-and-text-color;
    padding: 32px 0 19px 0;
    margin-bottom: 0;
  }
  .commerce-success-stories-sub-box-Text {
    font-size: 14px;
    color: $main-title-and-text-color;
    line-height: 17px;
    padding-bottom: 35px;
  }
  .commerce-success-stories-sub-box-Text p {
    margin-top: 0;
    margin-bottom: 0rem;
  }

  .ecommerce-success-stories-button-text {
    border: 2px solid #1c1b29;
    margin-top: 0px;
    border-radius: 30px;
    padding: 10px 55px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }

  .ecommerce-success-stories-button-box {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    color: $main-title-and-text-color;
    padding-bottom: 50px;
  }

  .ecommerce-success-stories-sub-box-button {
    border-radius: 30px;
    border: 2px solid $main-title-and-text-color;
    padding: 7.5px 25px;
    background-color: $main-white-color;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    margin-bottom: 35px;
  }
  .ecommerce-success-stories-sub-box-box {
    padding-top: 0;
  }
}
