.boxWrapper {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;

  margin-right: 3.125vw;
  .img {
    width: 2.6049vw;
    height: 2.6049vw;
  }
  .textBox {
    margin-left: 1.04166666667vw;
    .title {
      margin-bottom: 5px;
    }
    .title p {
      margin-top: 0;
      margin-bottom: 0rem;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .boxWrapper {
    margin-right: 60px;
    margin-top: 25px;
    .img {
      width: 30px;
      height: 30px;
    }
    .textBox {
      margin-left: 10px;

      .title {
        margin-bottom: 2px;
      }
    }
  }
}
