.mainBox {
  color: #1c1b29;
  cursor: pointer;
  .JobBox {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: baseline;
    margin: 1.5624999999vw 0;
    .title {
      font-size: 1.2vw;
      line-height: 1.302081vw;
      p {
        line-height: 17px;
      }
    }
    .time {
      color: #767575;
    }
  }

  .descriptionBox {
    border-bottom: solid 1px #767575;
    padding: 0 0 1.5624999999vw 0;
    .descriptionRow {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: flex-start;
    }
    .description {
      display: flex;
      font-size: 1vw;
      line-height: 1.822919vw;
    }
    .descriptionText {
      line-height: 1.822919vw;
      margin-left: 0.5208333333vw;
    }
  }
  .descriptionBox p {
    margin-top: 0;
    margin-bottom: 0rem;
  }
}

@media only screen and (max-width: 1024px) {
  .mainBox {
    margin: 20px;
    .JobBox {
      margin: 20px 0;
      .title {
        font-size: 14px;
        line-height: 14px;
      }
      .time {
        font-size: 12px;
        line-height: 12px;
      }
    }

    .descriptionBox {
      padding: 0 0 30px 0;
      .description {
        font-size: 12px;
        line-height: 1.67;
      }
      .descriptionText {
        font-size: 12px;
        line-height: 1.67;
        margin-left: 10px;
      }
    }
  }
}
