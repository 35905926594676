.text {
  font-size: 1.041669vw;
  line-height: 1.041669vw;
  color: #1c1b29;
}
@media only screen and (max-width: 1024px) {
  .text {
    font-size: 14px;
    line-height: 14px;
  }
}
