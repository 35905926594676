@import "../../../styles/_const.scss";

//-----------------------Get In Touch Section

.idContact {
  padding: 9.2593vh 0 0 0;

  color: transparent;
}

.get-in-touch-section-title {
  padding-bottom: 9.2593vh;
  color: #1c1b29;
  text-align: center;
  font-size: 2.34375vw;
  line-height: 2.864585vw;
  font-weight: bold;
}

//-----------------------Get In Touch Section Box

.get-in-touch-section-box-box {
  display: grid;
  grid-template-columns: 33.333% 33.333% 33.334%;
  color: $main-white-color;
}

//-----------------------Get In Touch Section Box Items

.get-in-touch-section-box-items-title {
  font-size: 1.5625vw;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  line-height: 30px;
}
.get-in-touch-section-box-items-text {
  font-size: 1.041666vw;
  text-align: center;

  padding: 0 15%;

  margin-bottom: 2.7778vh;
}
.ENG-get-in-touch-section-box-items-text {
  font-size: 1.041666vw;
  text-align: center;

  padding: 0 16.5%;

  margin-bottom: 2.7778vh;
}

.get-in-touch-section-box-items-info {
  font-size: 1.041669vw;
  text-align: center;

  min-height: 7.408vh;
  position: relative;
}
.get-in-touch-section-box-items-button-box {
  display: grid;
  padding: 9.2593vh 0 4.62963vh 0;
  justify-items: center;
  font-size: 1.041669vw;
  line-height: 1.041669vw;
}
.get-in-touch-section-box-items-button {
  border: 3px solid $main-white-color;
  border-radius: 30px;
  background: transparent;
  color: $main-white-color;
  padding: 1.48148vh 2.864586vw;
  justify-self: center;
  font-weight: bold;
  outline: none;
  transition: 0.5s;
}
.get-in-touch-section-box-items-button:hover {
  background-color: #77767f91;
  transition: 0.5s;
}
.get-in-touch-section-box-items-icon {
  padding-top: 50px;
  padding-bottom: 20px;
  margin-left: 50%;
  transform: translate(-50%, 0);
}

.get-in-touch-section-box-items-info1 {
  color: transparent;
}

.getintouchBG {
  opacity: 0.8;
}
.get-in-touch-section-box-items-info p {
  margin: 0;
}
.get-in-touch-section-box-items-info p:nth-child(1) {
  margin-left: 5px;
}
.red {
  color: red;
  display: inline-block;
}
.socialIconsWrapper {
  margin-left: 32.2%;
  max-height: 0px;
}
.socialIconsWrapper img {
  width: 2.6049vw;
  margin-left: 1.041669vw;
  cursor: pointer;
}
.get-in-touch-section-box-items-info a {
  text-decoration: none;

  color: white;
  font-weight: bold;
}
.socialMediaWrapper {
  justify-content: center;
  align-items: center;
  min-height: 7.408vh;

  margin-left: -1.04166666667vw;
  position: absolute;
  margin-left: 10.5%;
  max-height: 100px;
}
.perIcon {
  width: 2.60416666668vw;
  margin-left: 1.04166666667vw;
}
//------------------------------------------------------------------------------------------MOBILE VERSION
@media only screen and (max-width: 1024px) and (min-width: 851px) {
  .get-in-touch-section-box-box {
    display: grid;
    grid-template-columns: auto;
    color: $main-white-color;
  }
  .get-in-touch-section-box {
    padding-top: 35px;
  }
  .get-in-touch-section-title {
    padding-bottom: 50px;
    color: #1c1b29;
    text-align: center;
    font-size: 25px;
    line-height: 16px;
    font-weight: bold;
  }

  .get-in-touch-section-box-items-title {
    line-height: 30px;
    font-size: 25px;
  }
  .get-in-touch-section-box-items-text {
    font-size: 14px;
    width: 100%;
    padding: 0 30%;

    line-height: 16px;
    margin-bottom: 20px;
  }
  .getintouchBG:nth-child(2) .get-in-touch-section-box-items-info {
    margin-bottom: 0px;
  }
  .getintouchBG:nth-child(3) .get-in-touch-section-box-items-info {
    margin-bottom: 0px;
  }
  .get-in-touch-section-box-items-info {
    padding: 0;
    font-size: 14px;
  }
  .get-in-touch-section-box-items-button-box {
    margin-top: 00px;
    display: grid;
    padding: 20px 0 35px 0;
    justify-items: center;
  }
  .get-in-touch-section-box-items-button {
    border: 2px solid $main-white-color;
    border-radius: 30px;
    background: transparent;
    color: $main-white-color;
    padding: 14px 39px;
    justify-self: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 10px;
  }
  .get-in-touch-section-box-items-icon {
    padding-bottom: 15px;
    margin-left: 50%;
    transform: translate(-50%, 0);
    width: 8.534vw;
    padding-top: 35px;
  }
  .getintouchBG:nth-child(1) .get-in-touch-section-box-items-icon {
    width: 30px;
  }
  .getintouchBG:nth-child(2) .get-in-touch-section-box-items-icon {
    width: 47.02px;
  }
  .getintouchBG:nth-child(3) .get-in-touch-section-box-items-icon {
    width: 19.66px;
  }

  .socialIconsWrapper,
  .socialMediaWrapper {
    max-height: 30px;
    position: relative;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }
  .socialIconsWrapper img,
  .socialMediaWrapper img {
    width: 30px;
    height: 30px;
    margin-left: 20px;
    cursor: pointer;
  }
  .socialIconsWrapper a:nth-child(1) img {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 850px) {
  .idContact {
    padding: 0;
  }
  .get-in-touch-section-box-box {
    display: grid;
    grid-template-columns: auto;
    color: $main-white-color;
  }
  .get-in-touch-section-box {
    padding-top: 50px;
  }
  .get-in-touch-section-title {
    padding-bottom: 50px;
    color: #1c1b29;
    text-align: center;
    font-size: 25px;
    line-height: 16px;
    font-weight: bold;
  }

  .get-in-touch-section-box-items-title {
    line-height: 30px;
    font-size: 25px;
  }
  .get-in-touch-section-box-items-text {
    font-size: 14px;
    width: 100%;
    padding: 0 43px;
    line-height: 16px;
    margin-bottom: 20px;
  }
  .getintouchBG:nth-child(2) .get-in-touch-section-box-items-info {
    margin-bottom: 0px;
  }
  .getintouchBG:nth-child(3) .get-in-touch-section-box-items-info {
    margin-bottom: 0px;
  }
  .get-in-touch-section-box-items-info {
    padding: 0;
    font-size: 14px;
  }
  .get-in-touch-section-box-items-button-box {
    margin-top: 00px;
    display: grid;
    padding: 20px 0 35px 0;
    justify-items: center;
  }
  .get-in-touch-section-box-items-button {
    border: 2px solid $main-white-color;
    border-radius: 30px;
    background: transparent;
    color: $main-white-color;
    padding: 14px 39px;
    justify-self: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 10px;
  }
  .get-in-touch-section-box-items-icon {
    padding-bottom: 15px;
    margin-left: 50%;
    transform: translate(-50%, 0);
    width: 8.534vw;
    padding-top: 35px;
  }
  .getintouchBG:nth-child(1) .get-in-touch-section-box-items-icon {
    width: 30px;
  }
  .getintouchBG:nth-child(2) .get-in-touch-section-box-items-icon {
    width: 47.02px;
  }
  .getintouchBG:nth-child(3) .get-in-touch-section-box-items-icon {
    width: 19.66px;
  }

  .socialIconsWrapper,
  .socialMediaWrapper {
    max-height: 30px;
    position: relative;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }
  .socialIconsWrapper img,
  .socialMediaWrapper img {
    width: 30px;
    height: 30px;
    margin-left: 20px;
    cursor: pointer;
  }
  .socialIconsWrapper a:nth-child(1) img {
    margin-left: 0px;
  }
  .perIcon {
    width: 30px;
    margin-left: 20px;
  }
  .socialMediaWrapper {
    margin-left: -20px;
  }
}
