@import "../../../styles/_const.scss";

//--------------------------About Section
.idAbout {
  padding: 0px 0 9.2593vh 0;
  background-color: #efefef;
  color: transparent;
}
.about-section-box {
  background-color: #efefef;
}
.textColor {
  color: red;
}
.about-section-title {
  color: #1c1b29;
  text-align: center;
  font-size: 2.34375vw;
  line-height: 2.864585vw;
  font-weight: bold;
}

//--------------------About Section Box

.about-section-box-box {
  padding-top: 9.2593vh;
}

//-------------------------About Section First Row
.about-section-first-row-box {
  display: flex;

  justify-content: center;
  column-gap: 12vw;
}

//-------------------------About Section First Row Items

.about-section-first-row-items-text {
  width: 35vw;
  text-align: center;

  letter-spacing: 0px;
  color: #767575;
  line-height: 1.5625vw;

  font-size: 1.041669vw;
}
.about-section-pics {
  margin-bottom: 30px;
  width: 34.65vw;
  border-bottom: 1px solid $main-grey-color;
  padding-bottom: 30px;
}

//----------------------- About Section Box Second Row

.about-section-box-second-row-box {
  display: grid;
  grid-template-columns: 22% 22% 22% 22%;
  justify-content: center;
  column-gap: 2.5vw;
  padding-top: 6.48148vh;
  justify-self: center;
}
.ENAboutSectionBoxSecondRowBox {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: center;

  column-gap: 5.5vw;

  padding-top: 6.48148vh;
  justify-self: center;
}
//----------------------- About Section Box Second Row Items
.about-section-box-second-row-item-box {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  justify-self: center;
  margin-bottom: 7.62963vh;
}
.about-section-text-row-items-title {
  font-size: 1.5625vw;
  color: $main-grey-color;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid $main-grey-color;
}
.about-section-text-row-items-title p {
  margin-top: 0;
  margin-bottom: 0rem;
}
.about-section-text-row-items-text {
  width: 21vw;
  line-height: 1.354165vw;
  text-align: justify;
  letter-spacing: 0px;
  color: $main-grey-color;
  padding-top: 25px;
  font-size: 1.041666vw;
}
.ENAboutSectionTextRowItemsText {
  width: 15.9vw;

  text-align: justify;
  letter-spacing: 0px;
  color: $main-grey-color;
  padding-top: 30px;
  font-size: 1.041666vw;
}
.about-section-second-row-pics {
  margin-left: 50%;
  height: 10.32vh;
  transform: translate(-50%, 0px);
  margin-bottom: 2.77778vh;
}

// ..................... button
.buttonBox {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  color: $main-title-and-text-color;
}

.buttonContent {
  border: 2px solid #1c1b29;
  background-color: transparent;

  border-radius: 30px;
  padding: 1.48148vh 2.864586vw;
  font-weight: bold;
  font-size: 1.041669vw;
  line-height: 1.041669vw;
  outline: none;
  transition: 0.5s;
  margin-bottom: 9.2593vh;
}
.buttonContent:hover {
  background-color: #1c1b29;
  color: #efefef;
  transition: 0.5s;
}

//------------------------------------------------------------------------------------------MOBILE VERSION
@media only screen and (max-width: 1024px) and (min-width: 851px) {
  .about-section-title {
    font-size: 25px;
    line-height: 30px;
    padding-bottom: 0px;
  }

  .about-section-first-row-box {
    display: grid;
    grid-template-columns: auto;
  }

  .about-section-first-row-items-box {
    width: 100%;
    padding: 0 15%;
  }
  .about-section-first-row-items-text-box {
    width: 100%;
  }

  .about-section-first-row-items-text {
    border-top: 1px solid $main-grey-color;

    width: 90%;
    margin-left: 5%;

    padding-top: 18px;
    padding-bottom: 19px;
    font-size: 14px;
    line-height: 16px;
  }
  .about-section-pics {
    width: 57.86vw;
    padding-bottom: 20px;
    margin-bottom: 0px;
    border-bottom: none;
  }

  .about-section-first-row-items-box-box {
    display: flex;

    justify-content: center;
  }
  .about-section-box-second-row-box {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    column-gap: 110px;
    padding-top: 0px;
    padding: 0 17%;

    justify-self: center;
  }

  .about-section-second-row-items-box {
    display: grid;
    grid-template-columns: 100px auto;

    align-items: center;

    padding-top: 30px;
    margin-bottom: 10px;
  }
  .about-section-text-row-items-title-and-paragraph-box {
    border-left: 1px solid $main-grey-color;
  }
  .ENAboutSectionTextRowItemsText p {
    margin: 0;
  }

  .about-section-text-row-items-text {
    width: 100%;
    text-align: justify;
    letter-spacing: 0px;
    color: $main-grey-color;
    padding-top: 0px;
    font-size: 14px;
    line-height: 16px;
    padding-right: 18px;
    padding-left: 10px;
  }
  .about-section-text-row-items-title {
    font-size: 16px;
    line-height: 16px;
    color: $main-grey-color;
    font-weight: bold;
    text-align: left;
    border-bottom: none;
    padding: 5px 10px 5px 10px;
  }

  .about-section-second-row-pics {
    width: 76px;
    height: 76px;

    margin-bottom: 0;
  }
  .buttonBox {
    display: grid;
    grid-template-columns: auto;
    padding-top: 10px;
    justify-content: center;
    color: $main-title-and-text-color;
    margin: 30px 64px 0px 64px;
  }

  .buttonContent {
    border: 2px solid #1c1b29;
    margin-top: 0px;
    border-radius: 30px;
    padding: 14px 39px;
    font-weight: bold;
    font-size: 14px;
    line-height: 10px;
  }
}
@media only screen and (max-width: 850px) {
  .idAbout {
    padding: 0px 0 0px 0;
    background-color: #efefef;
    color: transparent;
  }
  .about-section-title {
    font-size: 25px;
    line-height: 30px;
    padding-bottom: 0px;
  }
  .about-section-box {
    padding: 50px 0 50px 0;
    background-color: #efefef;
  }
  .about-section-box-box {
    padding-top: 50px;
  }

  .about-section-first-row-box {
    display: grid;
    grid-template-columns: auto;
  }

  .about-section-first-row-items-box {
    width: 100%;
  }
  .about-section-first-row-items-text-box {
    width: 100%;
  }

  .about-section-first-row-items-text {
    border-top: 1px solid $main-grey-color;

    width: 90%;
    margin-left: 5%;

    padding-top: 18px;
    padding-bottom: 19px;
    font-size: 14px;
    line-height: 16px;
  }
  .about-section-pics {
    width: 57.86vw;
    padding-bottom: 20px;
    margin-bottom: 0px;
    border-bottom: none;
  }

  .about-section-first-row-items-box-box {
    display: flex;

    justify-content: center;
  }
  .about-section-box-second-row-box {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    column-gap: 110px;
    padding-top: 0px;

    justify-self: center;
  }

  .about-section-second-row-items-box {
    display: grid;
    grid-template-columns: 100px auto;
    // width: 100%;
    align-items: center;

    padding-top: 15px;
    margin-bottom: 10px;
  }
  .about-section-text-row-items-title-and-paragraph-box {
    border-left: 1px solid $main-grey-color;
  }
  .ENAboutSectionTextRowItemsText p {
    margin: 0;
  }

  .about-section-text-row-items-text {
    width: 100%;
    text-align: justify;
    letter-spacing: 0px;
    color: $main-grey-color;
    padding-top: 0px;
    font-size: 14px;
    line-height: 16px;
    padding-right: 18px;
    padding-left: 10px;
  }
  .about-section-text-row-items-title {
    font-size: 16px;
    line-height: 16px;
    color: $main-grey-color;
    font-weight: bold;
    text-align: left;
    border-bottom: none;
    padding: 5px 10px 5px 10px;
  }

  .about-section-second-row-pics {
    width: 76px;
    height: 76px;

    margin-bottom: 0;
  }
  .buttonBox {
    display: grid;
    grid-template-columns: auto;
    padding-top: 10px;
    justify-content: center;
    color: $main-title-and-text-color;
    margin: 30px 64px 0px 64px;
  }

  .buttonContent {
    border: 2px solid #1c1b29;
    margin-top: 0px;
    border-radius: 30px;
    padding: 14px 39px;
    font-weight: bold;
    font-size: 14px;
    line-height: 10px;
  }
}
