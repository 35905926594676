@import "../../../styles/_const.scss";

//...............................................Box For Box And Text
.ecommerce-page-box-for-box-and-text {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 59%;
}
//-----------------------------------------------Box with Buttons
.ecommerce-page-box-with-buttons-box {
  margin-top: -3.4815vh; //-6.4815vh;
  background: #f8f8f8;
  box-shadow: $main-box-shadow;
  z-index: 1;
}

.ecommerce-page-box-text {
  padding: 9.2593vh 5.72919vw 2.96296vh 5.72919vw;
  font-size: 1.562499vw;
  font-weight: bold;
  line-height: 2.395836vw;
}

.ecommerce-page-buttons {
  border: 3px solid #1c1b29;
  font-size: 1.041669vw;
  padding: 1.85185vh 1.864586vw;
  color: #1c1b29;
  border-radius: 35px;
  background: transparent;
  outline: none;
  font-weight: bold;
  transition: 0.2s;
  line-height: 1.041669vw;
}

.ecommerce-page-buttons:hover {
  background-color: #1c1b29;
  color: #efefef;
  transition: 0.2s;
}
.ecommerce-page-buttons:focus {
  outline: none;
}

.ecommerce-page-buttons-box {
  display: flex;
  justify-content: start;
  margin-left: 5.72919vw;
  column-gap: 2.6vw;
  margin-bottom: 9.2593vh;
}

//---------------------------------------Right Text

.ecommerce-page-right-text-box {
  display: grid;
  font-size: 1.302085vw;
  line-height: 1.822919vw;
  margin: 0 5.20835vw;
  align-items: center;
  text-align: justify;
}

.block {
  padding: 9.2593vh 5.72919vw 4.62965vh 5.72919vw;
}
.block p {
  margin-bottom: 50px;
}
//------------------------------------------------------------------------------------------MOBILE VERSION
@media only screen and (max-width: 1024px) and (min-width: 601px) {
  .ecommerce-page-box-for-box-and-text {
    display: grid;
    grid-template-columns: auto;
  }
  .ecommerce-page-box-with-buttons-box {
    margin-left: 15%;
    width: 70%;
    margin-top: -50px;
    background: #f8f8f8;
    z-index: 1;
    position: relative;
    box-shadow: $main-box-shadow;
  }

  .ecommerce-page-box-text {
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    position: relative;
    text-align: justify;
    padding: 35px 35px 20px 35px;
  }
  .ecommerce-page-buttons {
    border: 2px solid #1c1b29;
    font-size: 12px;
    color: #1c1b29;
    border-radius: 30px;
    background: transparent;
    outline: none;
    width: 20vw;
    height: 5.3vh;
    padding: 16px 19px 20px;
  }

  .ecommerce-page-buttons-box {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    margin-bottom: 35px;
    margin-right: 20px;
    margin-left: 0px;
    justify-content: center;
  }

  .ecommerce-page-right-text-box {
    padding: 50px 15% 0 15%;
    text-align: justify;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }
  .block {
    padding: 50px 5.72919vw 0 5.72919vw;
  }
  .block p {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 850px) and (min-width: 601px) {
  .ecommerce-page-box-for-box-and-text {
    display: grid;
    grid-template-columns: auto;
  }
  .ecommerce-page-box-with-buttons-box {
    margin-left: 15%;
    width: 70%;
    margin-top: -50px;
    background: #f8f8f8;
    z-index: 1;
    position: relative;
    box-shadow: $main-box-shadow;
  }

  .ecommerce-page-box-text {
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    position: relative;
    text-align: justify;
    padding: 35px 35px 20px 35px;
  }
  .ecommerce-page-buttons {
    border: 2px solid #1c1b29;
    font-size: 12px;
    color: #1c1b29;
    border-radius: 30px;
    background: transparent;
    outline: none;
    width: 20vw;
    height: 5.3vh;
    padding: 16px 19px 20px;
  }

  .ecommerce-page-buttons-box {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    margin-bottom: 35px;
    margin-right: 20px;
    margin-left: 0px;
    justify-content: center;
  }

  .ecommerce-page-right-text-box {
    padding: 50px 15% 0 15%;
    text-align: justify;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }
  .block {
    padding: 50px 5.72919vw 0 5.72919vw;
  }
  .block p {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 600px) {
  .ecommerce-page-box-for-box-and-text {
    display: grid;
    grid-template-columns: auto;
  }
  .ecommerce-page-box-with-buttons-box {
    margin-left: 5%;
    width: 90%;
    margin-top: -50px;
    background: #f8f8f8;
    z-index: 1;
    position: relative;
    box-shadow: $main-box-shadow;
  }

  .ecommerce-page-box-text {
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    position: relative;
    padding: 35px 15px 19px 15px;
  }
  .ecommerce-page-buttons {
    border: 2px solid #1c1b29;
    font-size: 12px;
    color: #1c1b29;
    border-radius: 30px;
    background: transparent;
    outline: none;
    width: 35vw;
    height: 5.3vh;
    padding: 1.85185vh 1.864586vw;
  }

  .ecommerce-page-buttons-box {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    margin-bottom: 35px;
    margin-right: 19px;
  }

  .ecommerce-page-right-text-box {
    padding: 50px 0 50px 0;
    text-align: justify;
    font-size: 14px;
    line-height: 16px;
    margin: 0 5.7vw;
  }
  .block {
    padding: 0px 5.72919vw 0 5.72919vw;
  }
  .block p {
    margin-bottom: 50px;
  }
}
