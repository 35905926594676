.boxStyle {
  width: 70%;
  padding: 3.64584vw 5.41669vw 5.20835vw 5.41669vw;

  background-color: white;
  box-shadow: 0px 10px 30px #00000033;
}
.textStyle {
  padding: 13.02081vw 0 5.20835vw 0;
  text-align: center;
}

.text {
  line-height: 1.45833333324vw;
}
@media only screen and (max-width: 1024px) {
  .textStyle {
    font-size: 25px !important;
  }
  .text {
    font-size: 12px;
    line-height: 20px;
  }
  .boxStyle {
    width: 90%;
    margin-left: 5%;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 501px) {
  .textStyle {
    padding: 17.21vw 0 5.20835vw 0;
  }
}
@media only screen and (max-width: 500px) and (min-width: 400px) {
  .textStyle {
    padding: 20.2084vw 0 5.20835vw 0;
  }
}
@media only screen and (max-width: 399px) {
  .textStyle {
    padding: 27.2057vw 0 5.20835vw 0;
  }
}
